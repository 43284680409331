import { Component, OnInit, OnChanges, EventEmitter, Input, Output, SimpleChanges, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EChartsOption, ECharts } from 'echarts';
import { CountryFlagService } from 'projects/helios-gui/src/uikit/country-flag/country-flag.service';
import { Subject, takeUntil } from 'rxjs';
import { HorizonProjectService } from '../../../../horizon-project/horizon-project.service';
import { AnalogueCotDataFormat } from '../../../../horizon-project/models/AnalogueCotDataFormat';
import { ValueFramework } from '../../../../horizon-project/models/ValueFramework';
import { AnalogueService } from '../../../../horizon-project/services/analogue.service';
import { ValueFrameworksService } from '../../../../horizon-project/services/value-frameworks.service';
import { chartCicleColorDecider } from '../../../../horizon-project/utility-functions/ReimbursedColorDecide';
import { ExportImageService } from '../../../../shared/services/export-image.service';
import { SharedService } from 'projects/helios-gui/src/uikit/service/shared.service';

@Component({
  selector: 'he-project-analogue-price-per-unit-chart',
  templateUrl: './project-analogue-price-per-unit-chart.component.html',
  styleUrls: ['./project-analogue-price-per-unit-chart.component.scss']
})
export class ProjectAnaloguePricePerUnitChartComponent implements OnInit , OnChanges,OnDestroy{
  public valueFrameworkData: ValueFramework[] = [];
  public analogueCotData: AnalogueCotDataFormat[] = [];

  @Input() selectedPriceType = '';
  @Output() chartInstance = new EventEmitter<any>();
  public chartType = 'ANALOGUE PRICE PER INPUT Charts';
  private unsubscriber$ = new Subject<void>();

  constructor(
    private valueFrameworkService: ValueFrameworksService,
    private anlogueService: AnalogueService,
    private countryFlagService: CountryFlagService,
    private projectService: HorizonProjectService,
    private activatedRouter: ActivatedRoute,
    private exportImageService: ExportImageService,
    private sharedService:SharedService
  ) {}

  public chartOption!: EChartsOption;
  public updateChartOption!: EChartsOption;
  chartSeriesData: any[] = [];

  // we can get the countries from the service
  countries: string[] = [];
  projectId = '';

  ngOnInit(): void {
    this.activatedRouter.queryParams.pipe(takeUntil(this.unsubscriber$)).subscribe((p) => {
      this.projectId = p.project;
      if (this.projectId) {
        this.loadData(this.projectId);
        this.initChartData();
      }
    });
  }

  /**
   * Prepares the formatted input structure for e-chart 
   */
  private initChartData() {
    this.chartOption = {
      grid: {
        left: '2%',
        right: '0%',
        bottom: '3%',
        containLabel: true
      },
      title: {
        text: 'Analogue:',
        left: '0px',
        top: 0,
        textStyle: {
          fontWeight: 700,
          fontSize: 14,
          lineHeight: 20,
          color: '#374050',
        },
        padding: [3, 0],
      },
      legend: {
        left: 100,
        textStyle: {
          fontWeight: 400,
          fontSize: 14,
          lineHeight: 20,
          verticalAlign: 'middle',
        },
      },
      tooltip: {
        formatter(params: any) {
          const data = params || [0, 0];
          return [
            data.seriesName,
            data.marker +
              data.name +
              ': &nbsp;&nbsp;' +
              '€' +
              parseFloat(data.value)
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
          ].join('<br/>');
        },
      },
      xAxis: {
        type: 'category',
        data: this.countries.map((x) => this.capitaliseString(x)),
        axisLabel: {
          formatter(value: any) {
            return (
              '{' +
              value.replace(' ', '').toUpperCase() +
              '| }  {value|' +
              value +
              '}'
            );
          },
          fontSize: 11,
          margin: 20,
          rich: {
            value: {
              lineHeight: 35,
              align: 'left',
            },
          },
        },
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          formatter: '€{value}',
        },
        min: 0,
      },
      color: [
        '#BD769B',
        '#CC9997',
        '#E0C784',
        '#D7CE6E',
        '#C5DD83',
        '#99CC9B',
        '#90B3D3',
        '#768ECE',
      ],
      series: [],
    };
  }

  /**
   * Load's the Cot data from the service.
   * @param projectId 
   */
  private async loadData(projectId: string) {
    if (projectId) {
      if (this.sharedService.isBrandCotDataCall$.value) {
        // Use existing data if the flag is true
        const cotData = this.sharedService.BrandCotDatadata$.value || [];
        this.analogueCotData = cotData;
        if (this.analogueCotData.length > 0) {
          this.formatChartData();
        }
      } else {
        // Make the API call if the flag is false
      await this.anlogueService
        .getAnalogueCotData(projectId)
        .then((cotData: AnalogueCotDataFormat[]) => {
          this.analogueCotData = cotData;
          this.sharedService.BrandCotDatadata$.next(cotData); // Update shared data
          this.sharedService.isBrandCotDataCall$.next(true); // Update flag to avoid future API calls
          if (this.analogueCotData.length > 0) {
            this.formatChartData();
          }
        });
    }
  }
  
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.chartSeriesData = [];
    this.formatChartData(changes.selectedPriceType.currentValue);
  }

  /**
   * format's the cot data into chart structure and select's the data based on pricetype to display in it.
   * @param priceType 
   */
  formatChartData(priceType?: string): void {

    this.initChartData();
    this.updateChartOption = {};
    this.chartSeriesData = [];
    this.countries = [];
    const selectedPrice = priceType ? priceType : 'launchPrice';
    const brands: string[] = [];
    this.analogueCotData.map((row: AnalogueCotDataFormat) => {
      if (!brands.includes(row.brandName)) {
        brands.push(row.brandName);
      }
      if (!this.countries.includes(row.country.toUpperCase())) {
        this.countries.push(row.country.toUpperCase());
      }
    });
    brands.sort();

    const countrysdata: any[] = [];
    this.countries.sort().reverse();

    // filter each countries data and push it to countrysdata[]
    this.countries.forEach((country) => {
      let countrydata: any[] = [];
      countrydata = this.analogueCotData.filter((row: any) => {
        return row.country.toLowerCase() === country.toLowerCase();
      });
      countrydata.sort(function(a, b) {
        if (a.brandName < b.brandName) {
          return -1;
        }
        if (a.brandName > b.brandName) {
          return 1;
        }
        return 0;
      });
      if (countrydata.length > 0) {
        countrysdata.push(countrydata);
      }
    });

    countrysdata.forEach((countryData) => {
      brands.forEach((brand) => {
        const bdata = countryData.filter((row: any) => {
          return row.brandName.toLowerCase() === brand.toLowerCase();
        });

        const temp = {
          value: bdata[0][selectedPrice],
          label: {
            show: true,
            formatter: '   ',
            borderColor: 'black',
            backgroundColor: chartCicleColorDecider(
              bdata[0]['reimbursedClassification']
            ),
            borderRadius: 20,
            position: 'bottom',
          },
        };
        this.AddInSeriesData(temp, brand);
      });
    });
    const xAxisLabel = this.xAxisLablePrepare();
    const tempBrands = brands.map(
      (x) => x.toUpperCase()[0] + x.toLowerCase().slice(1)
    );
    this.updateChartOption = {
      series: this.chartSeriesData,
      legend: { data: tempBrands },
      xAxis: {
        axisLabel: xAxisLabel,
        data: this.countries.map((x) => this.capitaliseString(x)),
      },
    };
  }

  /**
   * prepares the data that will be shown on the bar chart for each of the analogue
   * @param data 
   * @param brand 
   * @returns 
   */

  AddInSeriesData(data: any, brand: string) {
    let found = false;
    this.chartSeriesData.map((element) => {
      if (element.name.toLowerCase() === brand.toLowerCase()) {
        element.data.push(data);
        found = true;
        return;
      }
    });
    if (found) {
      return;
    }
    // did not found then add the element
    this.chartSeriesData.push({
      name: brand.charAt(0) + brand.slice(1).toLowerCase(),
      data: [data],
      type: 'bar',
      barGap: '10%',
      barCategoryGap: '10%',
      barMaxWidth: '10%',
    });
  }

  /**
   * prepares the label data for x axis countries available.
   * @returns 
   */
  xAxisLablePrepare(): any {
    const axisLabel: any = {
      formatter(value: any) {
        return (
          '{' +
          value.replace(' ', '').toUpperCase().trim() +
          '|}  ' +
          value
        );
      },
      margin: 20,
      rich: {
        value: {
          lineHeight: 35,
          align: 'center',
          color: 'black',
        },
      },
    };

    this.countries.forEach((element: string, index: number) => {
      axisLabel.rich[element.replace(' ', '').toUpperCase().trim()] = {
        height: 25,
        align: 'center',
        backgroundColor: {
          image:
            'assets/flags/' +
            this.countryFlagService.getFlagId(element) +
            '.svg',
        },
      };
    });
    return axisLabel;
  }

  /**
   * Helper function for capitalising the string.
   * @param data 
   * @returns 
   */
  capitaliseString = (data: string) => {
    let res = '';
    data.split(' ').forEach((element) => {
      res +=
        element.charAt(0).toUpperCase() + element.slice(1).toLowerCase() + ' ';
    });
    return res;
  }

  /**
   * For downloading the chart.
   * @param ev 
   */
  saveChartApi(ev: ECharts): void {
    this.exportImageService.registerApi(ev, this.chartType);
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }

}
