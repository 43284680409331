import { Component, OnDestroy, OnInit } from '@angular/core';
import { CountryFlagService } from 'projects/helios-gui/src/uikit/country-flag/country-flag.service';
import { HeaderService } from '../../../../shared/services/header.service';
import { ForecastService } from '../../services/forecast.service';
import {
  ValueForecastInput,
  BrandForecastData,
  ScoreMatrix,
  AssetForecastData,
} from '../../../../horizon-project/models/ValueForecastInput';
import { AnalogueService } from '../../../../horizon-project/services/analogue.service';
import { AnalogueCotDataFormat } from '../../../../horizon-project/models/AnalogueCotDataFormat';
import { ValueScoringService } from '../../../valuescoring/services/value-scoring.service';
import { ActivatedRoute } from '@angular/router';
import { HorizonProjectService } from '../../../../horizon-project/horizon-project.service';
import { ECharts } from 'echarts';
import { ExportImageService } from '../../../../shared/services/export-image.service';
import { Subject, takeUntil } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { WarningComponent } from '../../../../shared/components/warning/warning.component';
import { CurrencyConversionService } from '../../../../horizon-project/services/currency-conversion.service';
import { ToastNotificationService } from '../../../../shared/services/toast-notification.service';
import { SharedService } from 'projects/helios-gui/src/uikit/service/shared.service';
interface Dropdown {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'he-project-forecast',
  templateUrl: './project-forecast.component.html',
  styleUrls: ['./project-forecast.component.scss'],
})
export class ProjectForecastComponent implements OnInit, OnDestroy {
  countryName = '';
  assetData: any;
  data: any;
  filteredData: any;
  chartData: any;
  trendLine: any;
  valueScore: any;
  totalValueScore = 50;
  selectedCountry = '';
  flagId = '';
  valueScoreUpdate: boolean = false;
  loading: boolean = false;
  defaultBrands: any = [];
  updatedBrands: any = [];
  vScore: any = [];
public selectedRoute:string='Analgoues'
public prieceLabel:string='Price Analysis'
  priceType = 'launchPrice';
  previousriceType = 'launchPrice';
  assetName = '';
  projectId = '';
  cotData: any = [];
  minValueScore: any = '';
  scenario = '';
  chartName = 'Value Assessment Charts';
  infoData:any[]= [];
  currentPriceType = '';
  predictedPrice: number = 0;
  maxpredictedPrice: any = {name:'',price:0};
  minpredictedPrice: any = {name:'',price:0};
  assetDataName = '';
  selectedCurrency = '';
  selectedCurrencySymbol = '';
  priceTypes: Dropdown[] = [
    { value: 'launchPrice', viewValue: 'Launch Price' },
    { value: 'reimbursedPrice', viewValue: 'Reimbursed Price' },
    { value: 'currentPrice', viewValue: 'Current Price' },
  ];
  private unsubscriber$ = new Subject<void>();
  valueScoreRequestBody: ValueForecastInput = {
    ScoreMatrix: [],
    ProjectId: this.projectId,
  };

  constructor(
    private headerService: HeaderService,
    private countryFlagService: CountryFlagService,
    private forecastService: ForecastService,
    private analogueService: AnalogueService,
    private valueScoreService: ValueScoringService,
    private route: ActivatedRoute,
    private projectService: HorizonProjectService,
    private exportImageService: ExportImageService,
    public dialog: MatDialog,
    private currencyConversionService: CurrencyConversionService,
    private toastNotificationService: ToastNotificationService,
    public sharedService:SharedService
  ) {}

  ngOnInit(): void {
    this.headerService.ComparatorOrAnalog
    .pipe(takeUntil(this.unsubscriber$))
    .subscribe((data: string) => {
      this.selectedRoute = data;
      if( this.selectedRoute === 'Comparators'){
      this.prieceLabel = 'Price Corridor'
      }else{
        this.prieceLabel = 'Price Analysis'
      }
    });
    this.route.queryParams
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(async (f) => {
        await this.initializeCompData();
        this.loading=true;
        this.sharedService.isLoading$.next(true);
        this.projectId = f.project;
        if (this.projectId) {
          this.scenario = '';
          this.predictedPrice = 0;
          this.initData(this.priceType);
          this.headerService.setSelectedPriceType(this.priceType);
          this.selectedCountry = '';
          this.valueScoreUpdate = false;
        }

        this.loading=false;
        this.sharedService.isLoading$.next(false);
      })

    // clean country name
    // this.selectedCountry = this.headerService.getSelectedCountry();
    // this.selectedCountry = this.selectedCountry.replace('-', ' ');

    this.headerService.onScenarioChange
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((data) => {
        this.loading=true;
        this.sharedService.isLoading$.next(true);
        this.predictedPrice = 0;
        this.scenario = data;
        if (this.scenario != '' && this.projectId) {
          this.valueScoreUpdate = false;
          this.initData(this.priceType);
        }
      });

    this.headerService.onCountryChanged
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(async (country) => {
        this.loading=true;
        this.sharedService.isLoading$.next(true);
        this.predictedPrice = 0;
        this.valueScoreUpdate = false;
        this.selectedCountry = country.replace('-', ' ');
        // this.headerService.setSelectedPriceType(this.priceType);
        // const ScoreMatrixData = this.valueScoreRequestBody.ScoreMatrix.filter(
        //   (f) => f.CountryName === this.selectedCountry
        // );
        // if (ScoreMatrixData[0] != undefined) {
        //   ScoreMatrixData[0].BrandData = ScoreMatrixData[0].BrandData.filter(
        //     (brand) => brand.ActualPrice > 0
        //   );
        // }

        // const requestData: ValueForecastInput = {
        //   ScoreMatrix: ScoreMatrixData,
        //   ProjectId: this.projectId,
        // };
        const selectedCurrency =
        this.currencyConversionService.GetSelectedCurrency().value;

        await this.setCountryCurrency(selectedCurrency);

        // this.getForecastedData(requestData);
        this.initData(this.priceType)
 
      });

    // read the event of asset data change
    // trigger regression
    this.headerService.onBrandChanged
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((brands) => {
        this.loading=true;
        this.sharedService.isLoading$.next(true);
        const ScoreMatrixData = this.valueScoreRequestBody.ScoreMatrix.filter(
          (f) => f.CountryName === this.selectedCountry.replace('-', ' ')
        );

        if (brands.length > 1) {
          this.updatedBrands = brands;

          const brandData: BrandForecastData[] = JSON.parse(
            JSON.stringify(ScoreMatrixData[0].BrandData)
          );

          const UpdatedScoreMatrixData: ScoreMatrix[] = JSON.parse(
            JSON.stringify(ScoreMatrixData)
          );
          UpdatedScoreMatrixData[0].BrandData = [];

          brandData.forEach((f) => {
            if (
              brands.filter(
                (x) => x.toLowerCase().trim() == f.Name.toLowerCase().trim()
              ).length > 0
            ) {
              f.UseForRegression = true;
              UpdatedScoreMatrixData[0].BrandData.push(f);
            }
          });
          UpdatedScoreMatrixData[0].BrandData =
            UpdatedScoreMatrixData[0].BrandData.filter(
              (brand) => brand.ActualPrice > 0
            );

          const newRequestData: ValueForecastInput = {
            ScoreMatrix: UpdatedScoreMatrixData,
            ProjectId: this.projectId,
          };
          this.getForecastedData(newRequestData);
    }
  });
    // On price change trigger regression
    this.headerService.onPriceTypeChanged
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((priceType) => {
        this.loading=true;
        this.sharedService.isLoading$.next(true);
        // fetch brand cot data and filter it using pricetype
        // consturct the forecast request object
        // using TVS and COT data
        this.priceType=priceType;
        this.currentPriceType = priceType;       
        this.initData(priceType);
      });

    this.headerService.onValueScoreChanged
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((vScore) => {
        // update asset value score of that country
        if (vScore) {
          this.loading=true;
          this.sharedService.isLoading$.next(true);
          this.valueScoreUpdate = true;
          this.vScore = vScore;
          this.updateScores(this.vScore);
        }
      });

    this.currencyConversionService.selectedCurrency
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(async (currency: string) => {
        this.loading=true;
        this.sharedService.isLoading$.next(true);
        this.predictedPrice = 0;
        //this.selectedCurrency = currency;
        
        await this.setCountryCurrency(currency);

        const selectedProjectId =
          await this.projectService.GetSelectedProjectId();
        if (
          this.scenario != '' &&
          this.projectId &&
          this.projectId == selectedProjectId
        ) {
          const priceType = this.headerService.getSelectedPriceType();
          this.initData(priceType);
        }
      });

      this.loading=false;
      this.sharedService.isLoading$.next(false);
  }

  private async setCountryCurrency(currency: string) {
    if (currency.toLowerCase().trim() == 'local') {
      // this.selectedCurrency =
      //   this.currencyConversionService.GetDefaultCurrency();
      // this.selectedCurrencySymbol =
      //   this.currencyConversionService.GetDefaultCurrencySymbol();

      await this.currencyConversionService
        .getCountryCurrencyData(this.selectedCountry)
        .then(async (countryCur) => {
          this.selectedCurrency = countryCur.currencyCode;

          const hexCountrySymbol = countryCur.symbol.toUpperCase();

          if (hexCountrySymbol.includes('&#X')) {
            const decimalCurrCode = parseInt(
              hexCountrySymbol.replace('&#X', '').trim(),
              16
            );

            this.selectedCurrencySymbol = String.fromCharCode(decimalCurrCode);
          } else {
            this.selectedCurrencySymbol = String.fromCharCode(
              countryCur.symbol.replace('&#', '')
            );
          }

          //this.selectedCurrencySymbol = countryCur.symbol;
        });
    } else {
      this.selectedCurrency = currency;
      this.selectedCurrencySymbol =
        this.currencyConversionService.GetSelectedCurrencySymbol();
    }
  }

  public updateScores(vScore: any): void {
    const countryDataIndex = vScore.UpdatedCountryScores.findIndex(
      (f: any) => f.country === this.selectedCountry
    );

    if (countryDataIndex > -1) {
      const totalValueScore =
        vScore.UpdatedCountryScores[countryDataIndex].totalValueScore;
      const assetName = vScore.UpdatedCountryScores[countryDataIndex].assetName;

      const ScoreMatrixData = this.valueScoreRequestBody.ScoreMatrix.filter(
        (f) => f.CountryName === this.selectedCountry
      );

      if (ScoreMatrixData) {
        ScoreMatrixData[0].AssetData.forEach((a) => {});

        const UpdatedScoreMatrixData: ScoreMatrix[] = JSON.parse(
          JSON.stringify(ScoreMatrixData)
        );

        UpdatedScoreMatrixData[0].AssetData = [];

        UpdatedScoreMatrixData[0].AssetData.push({
          Name: assetName,
          TotalValueScore: totalValueScore,
        });

        if (this.updatedBrands.length > 0) {
          UpdatedScoreMatrixData[0].BrandData.forEach((f) => {
            f.UseForRegression = false;
          });

          this.updatedBrands.forEach((brand: string) => {
            if (
              UpdatedScoreMatrixData[0].BrandData.some(
                (f) => f.Name.toLowerCase() === brand.toLowerCase()
              )
            ) {
              UpdatedScoreMatrixData[0].BrandData.filter(
                (f) => f.Name.toLowerCase() === brand.toLowerCase()
              ).forEach((f) => (f.UseForRegression = true));
            }
          });
        }
        UpdatedScoreMatrixData[0].BrandData =
          UpdatedScoreMatrixData[0].BrandData.filter(
            (brand) => brand.ActualPrice > 0
          );
        const newRequestData: ValueForecastInput = {
          ScoreMatrix: [
            ...UpdatedScoreMatrixData,
            ...this.valueScoreRequestBody.ScoreMatrix.filter(
              (x) => x.CountryName !== this.selectedCountry
            ),
          ],
          ProjectId: this.projectId,
        };
        this.valueScoreRequestBody = JSON.parse(JSON.stringify(newRequestData));
        this.getForecastedData(newRequestData);
      }
    }
  }

  private async initializeCompData() {
    this.projectId = '';
    this.countryName = '';
    this.assetDataName = '';
    this.infoData = [];
    this.totalValueScore = 0;
    this.maxpredictedPrice = {name:'',price:0};
    this.minpredictedPrice = {name:'',price:0};;
    this.selectedCountry = '';
    this.flagId = '';
    this.defaultBrands = [];
    this.updatedBrands = [];
    this.data = undefined;
    this.chartData = undefined;
    this.assetData = undefined;
    this.valueScoreRequestBody = {
      ScoreMatrix: [],
      ProjectId: this.projectId,
    };
  }

  private async initData(priceType: string) {
    try {
      if (this.scenario != '') {
        const data = await this.getCotData(priceType);
        this.cotData = data;
        const preparedData = await this.prepareForecastRequestModel(
          this.cotData
        );
        this.valueScoreRequestBody = preparedData;

               // this.defaultBrands =
        //   this.valueScoreRequestBody.ScoreMatrix[0]?.BrandData.map(
        //     (a) => a.Name
        //   );

        this.defaultBrands = this.valueScoreRequestBody.ScoreMatrix.filter(
          (f) =>
            f.CountryName.toLocaleLowerCase() ===
            this.selectedCountry.toLocaleLowerCase()
        )[0]?.BrandData.map((a) => a.Name);

        this.valueScoreRequestBody.ScoreMatrix.forEach((ScoreMatrix) => {
          ScoreMatrix.BrandData = ScoreMatrix.BrandData.filter(
            (brand) => brand.ActualPrice > 0
          );
        });

        //logic changed. kept it just in case
        // if (this.valueScoreRequestBody.ScoreMatrix.length > 0)
        // this.checkPriceAvailability(this.valueScoreRequestBody);
        if (this.valueScoreRequestBody.ScoreMatrix.length > 0) {
          if (this.valueScoreUpdate) {
            this.updateScores(this.vScore);
          } else {
            this.getForecastedData(this.valueScoreRequestBody);
          }
        } else {
          this.data = {};
          this.filterChartData();
        }
      }
    } catch (error) {
      // Handle any errors that occurred during the asynchronous operations
      console.error(error);
    }
  }

  private async prepareForecastRequestModel(prices: any) {
    const ValueForecastInput: ValueForecastInput = {
      ScoreMatrix: [],
      ProjectId: this.projectId,
    };
    if (this.projectId && this.scenario != '') {
      const scores = !this.sharedService.isTotalValuescoreByCountriesCall$.value ? await this.valueScoreService.getTotalValueScoreByCountries(
        this.projectId,
        this.scenario
      ) : this.sharedService.TotalValuescoreByCountriesdata$.value ;
      this.sharedService.TotalValuescoreByCountriesdata$.next(scores);
      if (scores) {
        const valueScores = scores.valueScores.filter((x:any)=> x.country === this.selectedCountry);
        if (Array.isArray(valueScores)) {
          await Promise.all(
            valueScores.map(async (f: any) => {
              const countryName = f.country;
              let countryPrices: any = [];

              const scoreMatrix: ScoreMatrix = {
                CountryName: countryName,
                Info:[],
                AssetData: [],
                BrandData: [],
              };

              // get brand cot data
              countryPrices = prices.filter(
                (c: any) =>
                  c.country.toLowerCase() === countryName.toLowerCase()
              );
              await Promise.all(
                countryPrices.map(async (price: any) => {
                  scoreMatrix.Info = price.info
                  // for (const price of countryPrices) {
                  const ActualPrice = price.price;
                  const BrandName = price.brandName;
                  const SequenceNo = price.sequenceNo
                  const TotalValueScore = f.brands
                    .filter(
                      (b: any) =>
                        b.name.toLowerCase() === BrandName.toLowerCase()
                    )
                    .map((b: any) => b.totalValue)[0];

                  const BrandForecastData: BrandForecastData = {
                    Name: BrandName,
                    SequenceNo,
                    ActualPrice,
                    TotalValueScore,
                    UseForRegression: true,
                  };
                  scoreMatrix.BrandData.push(BrandForecastData);
                  // }
                })
              );

              const AssetForecastData: AssetForecastData = {
                Name: this.assetName,
                TotalValueScore: f.brands
                  .filter(
                    (b: any) =>
                      b.name.toLowerCase() === this.assetName.toLowerCase()
                  )
                  .map((b: any) => b.totalValue)[0],
              };

              scoreMatrix.AssetData.push(AssetForecastData);
              ValueForecastInput.ScoreMatrix.push(scoreMatrix);
            })
          );
        }
        this.refactorForcastData(ValueForecastInput);
      }
    }
    return ValueForecastInput;
  }
  
 
  private async getCotData(priceType: string) {
    const prices: any[] = [];
    if (this.projectId) {
      if (this.sharedService.isBrandCotDataCall$.value) {
        // Use existing data if the flag is true
        const newCotData: AnalogueCotDataFormat[] = JSON.parse(
          JSON.stringify(this.sharedService.BrandCotDatadata$.value || [])
        );
    
        await Promise.all(
          newCotData.map(async (element) => {
            element.launchPrice = await this.getCurrencyConvertedValue(element.launchPrice);
            element.currentPrice = await this.getCurrencyConvertedValue(element.currentPrice);
            element.reimbursedPrice = await this.getCurrencyConvertedValue(element.reimbursedPrice);
            element.netPrice = await this.getCurrencyConvertedValue(element.netPrice);
          })
        );
        await Promise.all(
          newCotData.map(async (f) => {
            const brandCotData = {
              brandName: f.brandName,
              country: f.country,
              info: f.infoIconData,
              tli: f.tli,
              price: 0,
              sequenceNo: f.sequenceNo,
            };
    
            switch (priceType.toLowerCase()) {
              case 'launchprice':
                brandCotData.price = f.launchPrice;
                break;
              case 'reimbursedprice':
                brandCotData.price = f.reimbursedPrice;
                break;
              case 'currentprice':
                brandCotData.price = f.currentPrice;
                break;
              case 'netprice':
                brandCotData.price = f.netPrice;
                break;
            }
    
            if (!prices.some((x) => x.brandName === f.brandName && x.country === f.country && x.tli === brandCotData.tli)) {
              prices.push(brandCotData);
            }
          })
        );
    
        this.assetName = this.headerService.assetName.value;
      //  return this.headerService.FrameworkID.value;
    
      } else {
        // Make the API call if the flag is false
        await this.analogueService
          .getAnalogueCotData(this.projectId)
          .then(async (data: AnalogueCotDataFormat[]) => {
            let newCotData: AnalogueCotDataFormat[] = JSON.parse(JSON.stringify(data));
            await Promise.all(
              newCotData.map(async (element) => {
                element.launchPrice = await this.getCurrencyConvertedValue(element.launchPrice);
                element.currentPrice = await this.getCurrencyConvertedValue(element.currentPrice);
                element.reimbursedPrice = await this.getCurrencyConvertedValue(element.reimbursedPrice);
                element.netPrice = await this.getCurrencyConvertedValue(element.netPrice);
              })
            );
            this.sharedService.BrandCotDatadata$.next(newCotData); // Update shared data
            this.sharedService.isBrandCotDataCall$.next(true); // Set the flag to true
            return newCotData;
          })
          .then(async (data: AnalogueCotDataFormat[]) => {
            await Promise.all(
              data.map(async (f) => {
                const brandCotData = {
                  brandName: f.brandName,
                  country: f.country,
                  info: f.infoIconData,
                  tli: f.tli,
                  price: 0,
                  sequenceNo: f.sequenceNo,
                };
    
                switch (priceType.toLowerCase()) {
                  case 'launchprice':
                    brandCotData.price = f.launchPrice;
                    break;
                  case 'reimbursedprice':
                    brandCotData.price = f.reimbursedPrice;
                    break;
                  case 'currentprice':
                    brandCotData.price = f.currentPrice;
                    break;
                  case 'netprice':
                    brandCotData.price = f.netPrice;
                    break;
                }
    
                if (!prices.some((x) => x.brandName === f.brandName && x.country === f.country && x.tli === brandCotData.tli)) {
                  prices.push(brandCotData);
                }
              })
            );
          })
          .then(async () => {
            this.assetName = this.headerService.assetName.value;
            return this.headerService.FrameworkID.value;
          });
      }
    }
    
    return prices;
  }

  private async getForecastedData(requestData: ValueForecastInput) {
    if (requestData != null && requestData.ScoreMatrix.length > 0) {
      if (this.projectId) {
        requestData.ScoreMatrix.forEach(
          (f) => (f.BrandData = f.BrandData.filter((b) => b.UseForRegression))
        );
        requestData.ProjectId = this.projectId;
        if(this.selectedRoute === 'Analogues'){
          await this.forecastService
          .getForecastData(requestData)
          .then((f) => {
            this.data = f;
            this.filterChartData();
          })
          .catch((err) => {
            console.log(err);
            this.toastNotificationService.errorMessage(
              'Unable to fetch forecast data',
              'info',
              10000
            );
            this.data = {};
            this.filterChartData();
          });
        }else{
          await this.forecastService
          .getComparatorForecastData(requestData)
          .then((f) => {
            this.data = f;
            this.filterChartData();
          })
          .catch((err) => {
            console.log(err);
            this.toastNotificationService.errorMessage(
              'Unable to fetch forecast data',
              'info',
              10000
            );
            this.data = {};
            this.filterChartData();
          });
        }

      }
    }
  }

  private async getMinValueScore(assetData: any, brandData: any) {
    const minValueScore = await assetData.totalValueScore;
    const minBrandValueScore = await brandData
      .map((b: any) => b.totalValueScore)
      .sort((a: any, b: any) => a - b)[0];
    let minValue =
      (await minValueScore) < minBrandValueScore
        ? minValueScore
        : minBrandValueScore;
    if (minValue % 10 >= 5) {
      minValue = Math.round(minValue / 10) * 10;
    }
    if (minValue % 10 < 5) {
      minValue = Math.round(minValue / 10) * 10 - 10;
    }
    return minValue;
  }
  async filterChartData() {
    if (this.selectedCountry !== '') {
      this.selectedCountry = this.selectedCountry.replace('-', ' ');
      this.flagId = this.countryFlagService.getFlagId(this.selectedCountry);

      if (
        Array.isArray(this.data.scoreMatrix) &&
        this.data?.scoreMatrix != undefined
      ) {
        this.filteredData = this.data?.scoreMatrix.filter(
          (item: any) =>
            item.countryName.toUpperCase() ===
            this.selectedCountry.toUpperCase()
        );
      } else {
        this.filteredData = [];
      }
    } else {
      this.flagId = '';
      this.filteredData = [];
    }
    if (this.filteredData.length > 0) {
      this.assetData = this.filteredData[0]?.assetData[0];
      this.assetDataName = this.filteredData[0]?.assetData[0]?.name;
      this.predictedPrice = Math.round(
        this.filteredData[0]?.assetData[0]?.predictedPrice
      );
      let brandPrices = this.filteredData[0]?.brandData.sort((a:any,b:any)=> a.actualPrice - b.actualPrice)
      if(this.selectedRoute === 'Comparators'){
        this.prieceLabel =brandPrices.length <= 1 ?  'Price Benchmark'  : 'Price Corridor';
      }
      this.minpredictedPrice = {name:brandPrices[0].name,price:Math.round(brandPrices[0].actualPrice)} ;
      this.maxpredictedPrice = {name:brandPrices[brandPrices.length - 1].name,price:Math.round(brandPrices[brandPrices.length - 1].actualPrice)};
      this.assetData.totalValueScore = Math.round(
        this.assetData?.totalValueScore
      );
      this.assetData.predictedPrice = Math.round(
        this.assetData?.predictedPrice
      );
      this.countryName = this.filteredData[0]?.countryName;
      this.infoData = this.valueScoreRequestBody.ScoreMatrix.filter(x=>x.CountryName === this.countryName )[0].Info
      this.chartData = this.filteredData[0]?.brandData;
      this.trendLine = this.filteredData[0]?.trendLine;
      this.totalValueScore = this.filteredData[0]?.totalHighestValueScore;
      this.valueScore =
        (this.assetData.totalValueScore / this.totalValueScore) * 100;
      this.minValueScore = await this.getMinValueScore(
        this.filteredData[0]?.assetData[0],
        this.filteredData[0]?.brandData
      );
    } else {
      this.assetData = [];
      this.totalValueScore = 0;
      this.predictedPrice = 0;
      this.assetData.totalValueScore=[];
      this.assetData.predictedPrice=[];
      this.valueScore = 0;
      this.chartData = [];
      this.trendLine = [];
      this.minValueScore = 0;
      this.countryName = this.selectedCountry;
      this.assetDataName =  this.headerService.projectName.value;
      if (this.valueScoreRequestBody?.ScoreMatrix.length>0) {
      this.infoData =this.valueScoreRequestBody.ScoreMatrix.filter(x=>x.CountryName === this.countryName )[0].Info;
      }
      if(this.selectedRoute === 'Comparators'){
        this.prieceLabel ='Price Benchmark';
      }
      this.minpredictedPrice.price=0;
      this.maxpredictedPrice.price=0;

      this.checkPriceAvailability();
    }
    this.loading=false;
    this.sharedService.isLoading$.next(false);
  }

  refactorForcastData(ValueForecastInput: ValueForecastInput) {
    let count = 0;
    ValueForecastInput.ScoreMatrix.forEach((scoreMatrix, i) => {
      scoreMatrix.BrandData.forEach((brand) => {
        if (brand.ActualPrice > 0) {
          count++;
        }
      });
      if (count === 0) {
        ValueForecastInput.ScoreMatrix.splice(i, 1);
      }
    });
  }

  checkPriceAvailability() {
    //logic changed. kept it just incase
    // this.selectedCountry = this.headerService.getSelectedCountry();
    // if (
    //   this.headerService
    //     .getCountries()
    //     .filter(
    //       (x) =>
    //         x.toLowerCase().trim() === this.selectedCountry.toLowerCase().trim()
    //     ).length > 0
    // ) {
    //   let count = 0;
    //   ValueForecastInput.ScoreMatrix.filter(
    //     (x) => x.CountryName === this.selectedCountry
    //   ).length > 0
    //     ? count++
    //     : count;
    //   if (count == 0) {
    this.dialog
      .open(WarningComponent, {
        data: {
          message: `No ${this.priceSelection(
            this.currentPriceType
          )} Available for ${this.selectedCountry}`,
        },
      })
      .afterClosed()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((res) => {});
    //   }
    // }
  }

  priceSelection(priceType: string) {
    return this.priceTypes.filter((x) => x.value === priceType)[0].viewValue;
  }

  saveInstance(ev: ECharts): void {
    this.exportImageService.registerApi(ev, this.chartName);
  }

  async getCurrencyConvertedValue(data: number) {
    const numericValue = data;
    const defaultCurrency = this.currencyConversionService.GetDefaultCurrency();
    let convertedData = 0.0;
    if (this.selectedCurrency.toLowerCase().trim() === 'local') {
      convertedData = numericValue;
    } else {
      convertedData = await this.currencyConversionService.convertCurrency(
        numericValue,
        defaultCurrency,
        this.selectedCurrency
      );
    }
    return convertedData;
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
