<div class="d-flex d-align-c d-j-between margin-padding2">
  <div class="custom-input custom-autocomplete">
    <mat-form-field appearance="outline" id="search-analogue-field">
      <input
        matInput
        [formControl]="searchTable"
        placeholder="Search below table"
      />
      <mat-icon matPrefix>search</mat-icon>
    </mat-form-field>
  </div>
  <div class="d-flex d-align-c d-j-start"></div>
  <div class="d-flex d-align-c d-j-between legends">
    <p class="legends-main-title">Legend:</p>
    <div *ngFor="let legend of legends" class="d-flex d-align-c d-j-c">
      <div
        class="legend-color"
        [ngStyle]="{ 'background-color': legend.color }"
      ></div>
      <p class="legend-name">{{ legend.name }}</p>
    </div>
    <button mat-icon-button [matMenuTriggerFor]="filtermenu">
      <mat-icon style="color: #4d488c">filter_alt</mat-icon>
    </button>
    <div class="heat-filter-menu">
      <mat-menu class="heat-filter-menu" #filtermenu="matMenu" xPosition="before">
        <div class="" mat-menu-content>
            <button mat-menu-item
              [ngClass]="mostmet ? 'highlight' : ''"
              (click)="filterbyHeat('Most met')"
            >
            Most criteria met
            </button>
            <button mat-menu-item
              [ngClass]="leastmet ? 'highlight' : ''"
              (click)="filterbyHeat('least met')"
            >
            Least criteria met
            </button>
        </div>
      </mat-menu>
    </div>
    <button mat-icon-button [matMenuTriggerFor]="menu">
      <mat-icon style="color:#4d488c;">settings</mat-icon>
    </button>
    <mat-menu class="" #menu="matMenu" xPosition="before">
      <div class="menu-scrollable" mat-menu-content>
        <ng-container *ngFor="let dynamicColum of dynamicColums">
          <div
          mat-menu-item
          class="criteria-toggle"
          (click)="$event.stopPropagation()"
          dynamicColums
        >
          <mat-slide-toggle
            class="example-margin"
            (change)="dynamicColum.visible = !dynamicColum.visible"
            [checked]="dynamicColum.visible"
            [disabled]="dynamicColum.default"
          >
            {{ dynamicColum.title }}
          </mat-slide-toggle>
        </div>
        </ng-container>
      </div>
      <div mat-menu-content class="d-flex d-j-around">
        <button mat-button >Cancel</button>
        <button mat-button class="btn-01" (click)="saveNewList()" >Save</button>
      </div>

    </mat-menu>
  </div>
</div>
<div class="margin-padding3">
  <div class="table-container analogue-selection-table">
    <ng-container *ngIf="loading2">
      <he-loading-spinner
        [diameter]="70"
        [height]="'350px'"
      ></he-loading-spinner>
    </ng-container>
    <ng-container *ngIf="!loading2">
      <table
        #tableheatmap
        mat-table
        [dataSource]="dataSource"
        class="mat-elevation-z8"
        matSort
        (matSortChange)="sortData($event)"
      >
        <ng-container
          [matColumnDef]="column.name"
          *ngFor="let column of displayedColumns"
          [sticky]="column.sticky"
        >
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header="{{ column.headerName }}"
          >
            {{ column.headerName }}
          </th>
          <div [ngSwitch]="column.name">
            <div
              *ngSwitchCase="'toggle'"
              class="custom-toogle custom-toogl-width"
            >
              <td mat-cell *matCellDef="let element" class="criteria-toggle">
                <mat-slide-toggle
                  class="example-margin"
                  (change)="selectBrand($event.checked, element)"
                  [checked]="element?.toggle.toggle"
                  [disabled]="element?.toggle.disable"
                >
                </mat-slide-toggle>
              </td>
            </div>
            <div
              *ngSwitchCase="'Brand'"
              class="custom-toogle custom-toogl-width"
            >
              <td mat-cell *matCellDef="let element">{{ element?.Brand }}</td>
            </div>
            <div *ngSwitchCase="'INN'" class="custom-toogle custom-toogl-width">
              <td mat-cell *matCellDef="let element">{{ element?.INN }}</td>
            </div>
            <div
              *ngSwitchCase="'Country'"
              class="custom-toogle custom-toogl-width"
            >
              <td mat-cell *matCellDef="let element">
                <div class="country-list">
                  <ng-container *ngFor="let market of element.Country">
                    <img
                      #tooltip="matTooltip"
                      matTooltip="{{ market?.name }}"
                      class="flag"
                      src="{{ 'assets/flags/' + market?.id + '.svg' }}"
                      width="22px"
                    />
                  </ng-container>
                </div>
              </td>
            </div>
            <div *ngSwitchDefault>
              <td
                mat-cell
                *matCellDef="let element"
                [ngStyle]="{ 'background-color': element[column.name]?.color }"
              >
                {{ element[column.name].name }}
              </td>
            </div>
          </div>
        <td mat-cell *matCellDef="let element">{{ element[column.name] }}</td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
        <tr mat-row  *matRowDef="let row; columns: columnsToDisplay"></tr >
        <tr class="mat-row" *matNoDataRow >
          <td class="mat-cell" colspan="10">
            <he-loading-spinner
              *ngIf="loading"
              [diameter]="70"
              [height]="'350px'"
            ></he-loading-spinner>
            <ng-container *ngIf="!loading">
              <div class="d-flex d-align-c d-j-c no-data">
                No data matching the filter
              </div>
            </ng-container>
          </td>
        </tr>
      </table>
    </ng-container>
    <!-- <ng-container *ngIf="!loading2">
    <table
    #tableheatmap
      mat-table
      [dataSource]="dataSource"
      matSort
      (matSortChange)="sortData($event)"
      id="analogue-heat-map-table"
    >
    <ng-container
    *ngFor="let column of displayedColumns"
    [matColumnDef]="column.name"
    [sticky]="column.sticky"
  >
    <th
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header="{{ column.headerName }}"
    >
      {{ column.headerName }}
    </th>
    <div [ngSwitch]="column.name">
      <div
        *ngSwitchCase="'toggle'"
        class="custom-toogle custom-toogl-width"
      >
        <td mat-cell *matCellDef="let element" class="criteria-toggle">
          <mat-slide-toggle
            class="example-margin"
            (change)="selectBrand($event.checked, element)"
            [checked]="element?.toggle.toggle"
            [disabled]="element?.toggle.disable"
          >
          </mat-slide-toggle>
        </td>
      </div>
      <div
        *ngSwitchCase="'Brand'"
        class="custom-toogle custom-toogl-width"
      >
        <td mat-cell *matCellDef="let element">{{ element?.Brand }}</td>
      </div>
      <div *ngSwitchCase="'INN'" class="custom-toogle custom-toogl-width">
        <td mat-cell *matCellDef="let element">{{ element?.INN }}</td>
      </div>
      <div
        *ngSwitchCase="'Country'"
        class="custom-toogle custom-toogl-width"
      >
        <td mat-cell *matCellDef="let element">
          <div class="country-list">
            <ng-container *ngFor="let market of element.Country">
              <img
                #tooltip="matTooltip"
                matTooltip="{{ market?.name }}"
                class="flag"
                src="{{ 'assets/flags/' + market?.id + '.svg' }}"
                width="22px"
              />
            </ng-container>
          </div>
        </td>
      </div>
      <div *ngSwitchDefault>
        <td
          mat-cell
          *matCellDef="let element"
          [ngStyle]="{ 'background-color': element[column.name]?.color }"
        >
          {{ element[column.name].name }}
        </td>
      </div>
    </div>
    <td mat-cell *matCellDef="let element">{{ element[column.name] }}</td>
  </ng-container>
      <tr mat-header-row *matHeaderRowDef="columnsToDisplay;sticky:true" ></tr>
      <tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="10">
          <he-loading-spinner
            *ngIf="loading"
            [diameter]="70"
            [height]="'350px'"
          ></he-loading-spinner>
          <ng-container *ngIf="!loading">
            <div class="d-flex d-align-c d-j-c no-data">
              No data matching the filter
            </div>
          </ng-container>
        </td>
      </tr>
    </table>
  </ng-container> -->
  </div>
</div>
