import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  ColumnApi,
  GridApi,
  GridOptions,
  GridReadyEvent,
} from 'ag-grid-community';
import { emptyFormatter } from './empty.formatter';
import { GridSizeModeInterface } from './grid-interfaces';
import { ExportCsv } from '../../app/top-nav/export-csv.service';
import { SharedService } from '../service/shared.service';

@Component({
  selector: 'he-grid',
  templateUrl: './grid-common.component.html',
  styleUrls: ['./grid-common.component.scss'],
})
export class GridCommonComponent implements OnInit {
  @Input() sizemode: GridSizeModeInterface;

  @Input() columnDefs: any;
  @Input() rowData: any;
  @Input() frameworkComponents: any;
  @Input() suppressColumnVirtualisation: boolean = false;
  @Input() paginationEnable: boolean = true;
  @Input() pagination: boolean = true;

  @Output() gridReady = new EventEmitter();

  private gridApi: GridApi | undefined;
  private gridColumnApi: ColumnApi | undefined;
  gridOptions: GridOptions = {};

  paginationPageSize = 10;
  domLayout: 'normal' | 'autoHeight' | 'print' | undefined = 'normal';
  defaultColDef = {
    resizable: true,
    sortable: true,
    valueFormatter: emptyFormatter,
    // tooltipComponent: 'customTooltip',
  };
  loadingTemplate =
    '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>';
  headerName: any = '';

  constructor(public sharedService: SharedService) {
    this.sizemode = 'autosize';
  }

  ngOnInit(): void {
    if (this.rowData) {
      this.updateDataset(this.rowData);
    }
  }

  updateDataset(rowData: any): void {
    if (rowData) {
      this.rowData = rowData;
      this.domLayout = this.rowData.length <= 10 ? 'autoHeight' : 'normal';
      if (this.paginationEnable) {
        this.pagination = this.rowData.length > 10;
      }
    }
  }

  sizeToFit(): void {
    if (this.gridApi) {
      this.gridApi.sizeColumnsToFit();
    }
  }

  autoSizeAll(skipHeader: boolean): void {
    const allColumnIds: any[] = [];
    if (this.gridColumnApi) {
      const columns = this.gridColumnApi.getAllColumns();
      if (columns) {
        columns.forEach((column: any) => {
          allColumnIds.push(column.colId);
        });
      }
      this.gridColumnApi.autoSizeColumns(allColumnIds, skipHeader);
    }
  }

  onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    if (this.sizemode === 'autofit') {
      this.sizeToFit();
    } else if (this.sizemode === 'autosize') {
      this.autoSizeAll(false);
    }
    this.gridReady.emit(this.gridApi);

    //For column scroll when there is sorting applied
    if (this.sharedService.gridmodesortType$.value != 0) {
      this.findColumnIndexByHeaderValue(
        this.sharedService.gridmodesortColumnText$.value
      );
    }
  }

  findColumnIndexByHeaderValue(headerValue: string) {
    const allColumns = this.gridOptions?.columnApi?.getAllDisplayedColumns();
    const colIndex = allColumns?.findIndex(
      (col) => col.getColDef().headerName === headerValue
    );
    this.scrollToColumnByIndex(Number(colIndex));
  }

  scrollToColumnByIndex(colIndex: number) {
    const column = this.gridColumnApi?.getAllDisplayedColumns()[colIndex];
    if (column) {
      this.gridOptions?.api?.ensureColumnVisible(column);
    } else {
      console.error('Column not found or not visible.');
    }
  }
}
