import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { HeaderService } from '../../shared/services/header.service';
import { SharedService } from 'projects/helios-gui/src/uikit/service/shared.service';
import { ProjectService } from '../project/services/project.service';
import { Subject, takeUntil } from 'rxjs';
@Component({
  selector: 'he-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class DashboardComponent implements OnInit {
  public selectedRoute:string='Analgoues';
  public selectedIndex:number=0;
  private readonly destroying$: Subject<void> = new Subject<void>();
  constructor(private headerService: HeaderService, private sharedService: SharedService, private projectService:ProjectService) {}

  ngOnInit():void{
    this.selectedRoute='Analgoues';
    this.headerService.ComparatorOrAnalog
    .pipe(takeUntil(this.destroying$))
    .subscribe((data: string) => {
      this.selectedRoute = data;
    });
    // this.sharedService.selectedTabIndex$.subscribe(res=>{
      // this.selectedIndex = res;
    // })
  }
  tabClick(tab: any) {
    // this.selectedIndex  = tab.index;
    this.headerService.setActiveTabName(tab.tab.textLabel);
    this.sharedService.isTotalValuescoreByCountriesCall$.next(false);
    this.sharedService.isBrandCotDataCall$.next(false);    
  }

}
