import { PlatformUserAuthorizationModule } from './platform-user-authorization/platform-user-authorization.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {
  NgxGoogleAnalyticsModule,
  NgxGoogleAnalyticsRouterModule,
} from 'ngx-google-analytics';
import { AppInsightsService } from './app-insights/app-insights-logging.service';
import { HeliosApiModule } from '../helios-api';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthModule } from './auth';
import { BasisModule } from './basis';
import { TawkChatModule } from './chat/tawk-chat.module';
import { AuthGuardService } from './auth/auth-guard.service';
import { PlatformGuardService } from './auth/platform-guard.service';
import { DataStoreBase } from './common/common-data-store.service';
import { AccessDeniedComponent } from './access-denied/access-denied.component';
import { HttpClientModule } from '@angular/common/http';
import { HorizonProjectModule } from './horizon/horizon-project/horizon-project.module';
import { SessionStoreBase } from './common/session-store-service';
import { HorizonModule } from './horizon';
import { ModalModule } from 'ngx-bootstrap/modal';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { FeatureFlagApi } from '../helios-api/feature-flag/feature-flag-api';
// import { ChatModule } from './chat/chat.module';
import {
  LucideAngularModule,
  File,
  Home,
  Menu,
  User,
  ListEnd,
  FilePlus2,
  FileMinus2,
  CircleHelp,
  SendHorizontal,
  ArrowUp,
  MoveUp,
  Snowflake,
  ThumbsUp,
  ThumbsDown,
  Download,
} from 'lucide-angular';
import { ChatHistoryComponent } from './chat-history/chat-history.component';

@NgModule({
  declarations: [
    AppComponent,
    // HorizonComponent
  ],
  imports: [
    AppRoutingModule,
    BasisModule,
    BrowserAnimationsModule,
    BrowserModule,
    HeliosApiModule,
    NgxGoogleAnalyticsModule.forRoot(environment.googleAnalytics.key),
    NgxGoogleAnalyticsRouterModule,
    TawkChatModule,
    // ChatModule, // chat module from chat application
    ChatHistoryComponent,
    AuthModule,
    HttpClientModule,
    HorizonProjectModule,
    HorizonModule,
    ModalModule.forRoot(),
    StoreModule.forRoot({}, {}),
    EffectsModule.forRoot([]),
    LucideAngularModule.pick({File, Home, Menu, User,ListEnd,CircleHelp,FilePlus2,FileMinus2 ,SendHorizontal,ArrowUp,MoveUp ,Snowflake,ThumbsUp,ThumbsDown,Download})
  ],
  providers: [
    AuthGuardService,
    AppInsightsService,
    DataStoreBase,
    SessionStoreBase,
    PlatformGuardService,
    FeatureFlagApi,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
