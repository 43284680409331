import {
  AfterViewInit,
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  Output,
  Input,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  Inject,
  OnDestroy,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ValueScoringService } from '../../services/value-scoring.service';
import { HeaderService } from '../../../../shared/services/header.service';
import { Subject, takeUntil } from 'rxjs';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

export interface ValueScoring {
  [key: string]: string | number | boolean | Array<any> | any;
}

let ELEMENT_DATA: ValueScoring[] = [];

@Component({
  selector: 'he-project-value-scoring-main-grid',
  templateUrl: './project-value-scoring-main-grid.component.html',
  styleUrls: ['./project-value-scoring-main-grid.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProjectValueScoringMainGridComponent
  implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input() chartData: any;
  @Input() selectedCountry = '';
  @Input() selectedOption = 0;
  @Input() extraColumns: string[] = [];
  @Input() filteredDatacolumn: string[] = [];
  @Input() colorIndexList: number[] = [];
  @Output() extraColumnsEvent = new EventEmitter<number>();

  alwaysVisibleColumns = ['brandedInnName', 'inn', 'totalValueScore'];

  defaultColumns = [
    ...this.alwaysVisibleColumns,
    'burdonDomainValueScore',
    'productDomainValueScore',
    'trialDomainValueScore',
    'clinicalDomainValueScore',
    'otherDomainValueScore',
  ];

  displayedColumns: string[] = [...this.defaultColumns];

  burdenDescription =
    'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words...';
  productDescription = 'Product Description here...';

  headerColors = [
    '#D9DEE9',
    '#901812',
    '#D84B44',
    '#DE9B37',
    '#4AA47A',
    '#20704A',
  ];

  selectedIndex = 0;

  dataSource = new MatTableDataSource(ELEMENT_DATA);

  public assetName = '';

  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  stringifiedData: any;
  convertJson: any;
  projectId = '';
  private unsubscriber$ = new Subject<void>();
  griddata: any;

  constructor(
    private valueScoringService: ValueScoringService,
    private headerService: HeaderService,
    public dialog: MatDialog,
    private activatedRouter: ActivatedRoute
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    const sortedChartData = this.chartData.sort((a: any, b: any) => {
      return a.position - b.position;
    });
    this.chartData = sortedChartData;
    ELEMENT_DATA = [...this.chartData];
    this.dataSource = new MatTableDataSource(ELEMENT_DATA);
    this.stringifiedData = ELEMENT_DATA;
    switch (this.selectedIndex) {
      case 1:
        this.displayedColumns = [
          ...this.alwaysVisibleColumns,
          'burdonDomainValueScore',
          ...this.extraColumns,
        ];
        break;
      case 2:
        this.displayedColumns = [
          ...this.alwaysVisibleColumns,
          'productDomainValueScore',
          ...this.extraColumns,
        ];
        break;
      case 3:
        this.displayedColumns = [
          ...this.alwaysVisibleColumns,
          'trialDomainValueScore',
          ...this.extraColumns,
        ];
        break;
      case 4:
        this.displayedColumns = [
          ...this.alwaysVisibleColumns,
          'clinicalDomainValueScore',
          ...this.extraColumns,
        ];
        break;
      case 5:
        this.displayedColumns = [
          ...this.alwaysVisibleColumns,
          'otherDomainValueScore',
          ...this.extraColumns,
        ];
        break;
      default:
        this.displayedColumns = [...this.defaultColumns];
    }
  }

  ngOnInit(): void {
    this.activatedRouter.queryParams
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((p) => {
        this.projectId = p.project;
      });
    this.headerService.castAssetName
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((data) => {
        this.assetName = data;
      });
    const sortedChartData = this.chartData.sort((a: any, b: any) => {
      return a.position - b.position;
    });
    this.chartData = sortedChartData;
    ELEMENT_DATA = [...this.chartData];
    this.dataSource = new MatTableDataSource(ELEMENT_DATA);
  }

  openDialog(
    brandName: any,
    column: any,
    htaInfo: any,
    valueScopeReference: string
  ): void {
    this.dialog.open(HTALinkDialog, {
      width: '454px',
      data: {
        brandName: brandName,
        column: column,
        htaInfo: htaInfo,
        valueScopeReference: valueScopeReference,
      },
      panelClass: 'hta-dialog',
      backdropClass: 'hta-dialog-backdrop',
    });
  }

  private initialiseCompData() {
    this.selectedIndex = 0;
    this.dataSource = new MatTableDataSource(ELEMENT_DATA);
    this.assetName = '';
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  filterColumns(index: number) {
    if (this.selectedIndex === index) {
      this.selectedIndex = 0;
      this.extraColumnsEvent.emit(0);
    } else {
      this.selectedIndex = index;
      this.extraColumnsEvent.emit(index);
    }
  }

  colorIndex: number = 0;
  getTopColor(index: number) {
    this.colorIndex = this.colorIndexList[index];
    if (this.colorIndex == 0) {
      return '#D9DEE9';
    } else if (this.colorIndex >= 0.01 && this.colorIndex <= 1.25) {
      return '#901812';
    } else if (this.colorIndex >= 1.26 && this.colorIndex <= 2.5) {
      return '#D84B44';
    } else if (this.colorIndex >= 2.51 && this.colorIndex <= 3.75) {
      return '#DE9B37';
    } else if (this.colorIndex >= 3.76 && this.colorIndex <= 5.0) {
      return '#4AA47A';
    } else {
      return '#20704A';
    }
  }
  downloadData() {
    // this.convertJson = JSON.stringify(this.stringifiedData);
    // this.valueScoringService.downloadFile(
    //   this.convertJson,
    //   'ValueScoring-' + this.selectedCountry,
    //   this.filteredDatacolumn
    // );
    // new download
    this.headerService.analogAndComparator
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((data) => {
        this.griddata = data;
      });
    let newtest =this.valueScoringService.newDownloadFileValueTab(this.griddata)
      this.valueScoringService.newConvertToCSV(
        newtest,
        'ValueScoring-' + this.selectedCountry
    );
  }

  mylogger(ele: any) {
    return ele;
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'hta-link-dialog',
  templateUrl: 'hta-link-dialog.html',
  styleUrls: ['./project-value-scoring-main-grid.component.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class HTALinkDialog {
  constructor(
    public dialogRef: MatDialogRef<HTALinkDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
