import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject, takeUntil, take } from 'rxjs';
import { CapitalFirstLetter, Capitalise } from '../../../../horizon-project/utility-functions/StringFormatter';
import { HeaderService } from '../../../../shared/services/header.service';
import { ValueScoringService } from '../../../valuescoring/services/value-scoring.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatTableExporterDirective } from 'mat-table-exporter';
import { ProjectValueScoringSubGridComponent } from '../../../valuescoring/component-pieces/project-value-scoring-sub-grid/project-value-scoring-sub-grid.component';
import html2canvas from 'html2canvas';
import { SummaryService } from '../../services/summary.service';
import { SummaryAssetTppComponent } from '../../component-pieces/summary-asset-tpp/summary-asset-tpp.component';
import { SummaryCotForecastComponent } from '../../component-pieces/summary-cot-forecast/summary-cot-forecast.component';
import { ToastNotificationService } from '../../../../shared/services/toast-notification.service';
import { SharedService } from 'projects/helios-gui/src/uikit/service/shared.service';

@Component({
  selector: 'he-project-summary',
  templateUrl: './project-summary.component.html',
  styleUrls: ['./project-summary.component.scss'],
})
export class ProjectSummaryComponent implements OnInit,OnDestroy {
  @ViewChild(MatTableExporterDirective)
  exporter?: MatTableExporterDirective;
  @ViewChild(MatPaginator)
  @Output()
  chartInstance = new EventEmitter<any>();
  @Output() recordsCountEvent = new EventEmitter<number>(true);
  @ViewChild(ProjectValueScoringSubGridComponent)
  CSVDownloadCntry?: ProjectValueScoringSubGridComponent;
  @ViewChild(SummaryAssetTppComponent)
  summmaryAssetTpp?: SummaryAssetTppComponent;
  @ViewChild('dataToExport', { static: false }) public dataToExport:
    | ElementRef
    | any;
  @ViewChild('downloadLink') downloadLink: ElementRef | any;
  @ViewChild('canvas') canvas: ElementRef | any;
  @ViewChild(SummaryCotForecastComponent)
  private CotForecastComponent!: SummaryCotForecastComponent;
  isGridClicked = true;
  isChartClicked = false;
  fileType = 'grid';
  public viewMode = 'AbsoluteValue';
  subGridRecordCount = 0;
  public dataSource = new MatTableDataSource([{}]);
  public displayedColumns: string[] = ['brandedInnName'];
  public ScenarioDataList: any[] = [];
   public data: any[] = [];
  public alpha = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
  ];
  public apiData: any = {};
  public valueScoresData: any[] = [];
  public SummaryFormattedData: any[] = [];
  public gridFormattedData: any[] = [];
  public countries: string[] = [];
  public brands: any[] = [];
  public analogues: string[] = [];
  public analoguesData: any[] = [];
  public percentages: string[] = [
    '0%',
    '10%',
    '20%',
    '30%',
    '40%',
    '50%',
    '60%',
    '70%',
    '80%',
    '90%',
    '100%',
  ];
  public projectId = '';
  public scenario = '';
  public assetName = '';
  public loading = true;
  chartType = 'Total Value Scoring By Countries Charts';
  defaultScoreMode = '1'

  private unsubscriber$ = new Subject<void>();
  constructor(
    private activatedRouter: ActivatedRoute,
    private valueScoringService: ValueScoringService,
    public headerService: HeaderService,
    private summaryService: SummaryService,
    private toastNotificationService: ToastNotificationService,
    private sharedService:SharedService
  ) {}

  ngOnInit(): void {
    this.activatedRouter.queryParams
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((p) => {
        this.initialiseCompData();
        if (p.project) {
          this.dataSource = new MatTableDataSource([{}]);
          this.scenario = this.headerService.getScenario();
          this.projectId = p.project;
          this.getCotSummary();
        }
      });
    this.headerService.castAssetName
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((data) => {
        this.assetName = data;
      });

    this.headerService.onValueScoreChanged
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((data) => {
        this.loading = true;
        let scenarioIndex = this.ScenarioDataList.findIndex(
          (x) => x.scenario === this.scenario
        );
        data.UpdatedCountryScores.forEach((d: any) => {
          const countryDataIndex = this.ScenarioDataList[
            scenarioIndex
          ].data.valueScores.findIndex((f: any) => f.country === d.country);
          if (countryDataIndex > -1) {
            const brandDataIndex = this.ScenarioDataList[
              scenarioIndex
            ].data.valueScores[countryDataIndex].brands.findIndex(
              (f: any) => f.id === d.assetId
            );

            if (brandDataIndex > -1) {
              this.ScenarioDataList[scenarioIndex].data.valueScores[
                countryDataIndex
              ].brands[brandDataIndex].totalValue = d.totalValueScore;
              this.ScenarioDataList[scenarioIndex].data.valueScores[
                countryDataIndex
              ].brands[brandDataIndex].totalValueScorePercent =
                d.totalValueScorePercent;
            }
          }
        });

        this.initChartData();
      });
    this.headerService.onScenarioChange
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((data) => {
        this.loading = true;

        this.scenario = data;
        if (this.scenario) {
          this.getAssetValueScoreSummary()
        }
      });
  }

    public getCotSummary(): void {
    this.data = [];
    this.summaryService
      .getCOTSummary(this.projectId)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        (data) => {
          this.data = data.data;
        },
        (error) => {
          this.data =[]
          this.toastNotificationService.errorMessage(
            'Failed to get COT data',
            'info',
            10000
          );
        }
      );
  }

  public getAssetValueScoreSummary():void{
    this.summaryService.getAssetValueScoreSummary(this.projectId).subscribe(res=>{
      this.ScenarioDataList = res;
      this.loadDataforindividualScene();
      this.loadData(this.projectId, this.scenario);
    })
  }
  private async loadData(projectId: string, scenario: string) {
    if (projectId && scenario) {      
      const existingData = this.sharedService.TotalValuescoreByCountriesdata$.value;
  
      if (existingData.valueScores.length != 0) {
        // Use existing data if available
        this.apiData = JSON.parse(JSON.stringify(existingData));
        this.valueScoresData = this.apiData.valueScores;
      } else {
        // Fetch data from the API if not available
      await this.valueScoringService
        .getTotalValueScoreByCountries(projectId, scenario)
        .then((data) => {
          this.apiData = JSON.parse(JSON.stringify(data));
          this.valueScoresData = this.apiData.valueScores;
          this.sharedService.TotalValuescoreByCountriesdata$.next(data); // Cache the fetched data
        });
    }

    // Initialize charts and reset scenario regardless of data source
    this.resetScenarioData();
    this.initChartData();
    this.loading = false;
  }
}
  public resetScenarioData(): void {
    if (this.ScenarioDataList.length != 0) {
      let scenarioIndex = this.ScenarioDataList.findIndex(
        (x) => x.scenario === this.scenario
      );
      this.valueScoresData.forEach((d: any) => {
        d.brands.sort((a: any, b: any) => {
          return a.sequenceNo - b.sequenceNo;
        });
        if(this.ScenarioDataList[
          scenarioIndex
        ]?.data != undefined){
          const countryDataIndex = this.ScenarioDataList[
            scenarioIndex
          ]?.data?.valueScores.findIndex((f: any) => f.country === d.country);
          if (countryDataIndex > -1) {
            const brandDataIndex = this.ScenarioDataList[
              scenarioIndex
            ].data.valueScores[countryDataIndex].brands.findIndex(
              (f: any) => f.id === d.brands[0].id
            );
  
            if (brandDataIndex > -1) {
              this.ScenarioDataList[scenarioIndex].data.valueScores[
                countryDataIndex
              ].brands[brandDataIndex].totalValue = d.brands[0].totalValue;
              this.ScenarioDataList[scenarioIndex].data.valueScores[
                countryDataIndex
              ].brands[brandDataIndex].totalValueScorePercent =
                d.brands[0].totalValueScorePercent;
            }
          }
        }

      });
    }
  }

  private initialiseCompData() {
    this.displayedColumns = ['brandedInnName'];
    this.loading = true;
    this.apiData = {};
    this.valueScoresData = [];
    this.gridFormattedData = [];
    this.SummaryFormattedData = [];
    this.analoguesData = [];
    this.analogues = [];
    this.countries = [];
    this.brands = [];
    this.projectId = '';
    this.scenario = '';
    this.assetName = '';
  }

  private initChartData() {
    this.countries = this.valueScoresData
      ? [
          ...new Set(
            this.valueScoresData.map((item) => {
              return Capitalise(item.country);
            })
          ),
        ]
      : [];

    this.countries.sort();
    const brandsData: any[] = this.valueScoresData[0].brands;
    this.brands = brandsData
      ? [
          ...new Set(
            brandsData.filter((x: any) => x.sequenceNo != 0).map((item: any) => {
              return item.sequenceNo > 0 ? Capitalise(item.name) : item.name;
            })
          ),
        ]
      : [];
    // this.brands.reverse();
    this.formatGridData();
    this.loadDataforindividualScene();
    setTimeout(() => {
      this.loading = false;
    }, 2000);
  }

  formatGridData() {
    this.gridFormattedData = [];
    this.SummaryFormattedData = [];
    this.analoguesData = [];
    this.analogues = [];
    // this.brands.shift();

    this.brands.forEach((brand) => {
      const SummaryformattedObject: any = {
        brandedInnName: brand,
        country: [],
      };
      const formattedObject: any = {
        brandedInnName: brand,
      };
      this.countries.forEach((country) => {
        const countryData = this.valueScoresData.find(
          (x) => x.country.toLowerCase() === country.toLowerCase()
        );
        countryData.brands.forEach((element: any) => {
          if (element.name.toLowerCase() === brand.toLowerCase()) {
            const tempTotal = Math.round(parseFloat(element.totalValue));
            const tempTotalPercent = Math.round(
              parseFloat(element.totalValueScorePercent)
            );
            const position = element.sequenceNo;
            if (
              !SummaryformattedObject.country.some(
                (x: any) => x.name.toLowerCase() === country.toLowerCase()
              )
            ) {
              SummaryformattedObject.country.push({
                name: country,
                percentage:
                  tempTotalPercent > 0
                    ? tempTotalPercent.toString() + '%'
                    : 'NA',
                secondaryPercentage:
                  tempTotalPercent > 0 ? tempTotalPercent : 'NA',
                zIndex: tempTotalPercent > 0 ? tempTotalPercent : 0,
              });
              SummaryformattedObject['position'] = position;
              SummaryformattedObject['inn'] = CapitalFirstLetter( element.inn);
              formattedObject['inn'] =  CapitalFirstLetter( element.inn);
              switch (this.viewMode) {
                case 'AbsoluteValue':
                  formattedObject[country] =
                    tempTotal > 0 ? tempTotal.toString() : 'NA';
                  formattedObject['position'] = position;                  
                  break;
                case 'Percentage':
                  formattedObject[country] =
                    tempTotalPercent > 0
                      ? tempTotalPercent.toString() + '%'
                      : 'NA';
                  formattedObject['position'] = position;
                  break;
                default:
                  formattedObject[country] =
                    tempTotal > 0 ? tempTotal.toString() : 'NA';
                  formattedObject['position'] = position;
                  break;
              }
            }
          }
        });
      });

      this.gridFormattedData.push(formattedObject);
      this.SummaryFormattedData.push(SummaryformattedObject);
    });
    this.displayedColumns = ['brandedInnName','inn', ...this.countries];
    this.SummaryFormattedData.forEach((element) => {
      element.country = this.compareValues(element.country);
    });
    this.gridFormattedData.sort((a: any, b: any) => {
      return a.position - b.position;
    });
    this.SummaryFormattedData.sort((a: any, b: any) => {
      return a.position - b.position;
    });
    this.dataSource = new MatTableDataSource(this.gridFormattedData);
    this.recordsCountEvent.emit(this.dataSource.data.length);
  }

  compareValues(country: any[]): any {
    let result: any[] = [...country];
    const change = 0.5;
    const change2 = 0.8;
    for (let i = 0; i < result.length; i++) {
      for (let j = i + 1; j < result.length; j++) {
        if (country[i].secondaryPercentage === country[j].secondaryPercentage) {
          if (country[j].secondaryPercentage != 'NA') {
            result[j].secondaryPercentage =
              result[j].secondaryPercentage + change;
            result[j].zIndex = Math.round(
              parseFloat(result[j].secondaryPercentage)
            );
            result[i].secondaryPercentage =
              result[i].secondaryPercentage - change;
            result[i].zIndex = Math.round(
              parseFloat(result[i].secondaryPercentage)
            );
          }
        }
      }
    }
    // might need this secondary check if more than 4 values are same
    for (let i = 0; i < result.length; i++) {
      for (let j = i + 1; j < result.length; j++) {
        if (
          Math.round(country[i].secondaryPercentage) ===
          Math.round(country[j].secondaryPercentage)
        ) {
          if (country[j].secondaryPercentage != 'NA') {
            result[j].secondaryPercentage =
              result[j].secondaryPercentage + change;
            result[j].zIndex = Math.round(
              parseFloat(result[j].secondaryPercentage)
            );
            result[i].secondaryPercentage =
              result[i].secondaryPercentage - change;
            result[i].zIndex = Math.round(
              parseFloat(result[i].secondaryPercentage)
            );
          }
        }
      }
    }
    return result;
  }

  private loadDataforindividualScene() {
    this.ScenarioDataList.forEach((data: any, index) => {
      let currentIndex = (data.sequenceNo === 0 ? index : data.sequenceNo ) - this.ScenarioDataList.length;
      let alphIndex = (data.sequenceNo === 0 ? index : data.sequenceNo - 1);
      let scenario = data.scenario;
      const formattedObject: any = {
        brandedInnName: '',
        position: currentIndex,
      };
      const SummaryformattedObject: any = {
        brandedInnName: '',
        country: [],
        position: currentIndex,
      };
      let newData = JSON.parse(JSON.stringify(data.data));
      newData.valueScores.forEach((a: any) => {
        a.brands.sort((a: any, b: any) => {
          return b.sequenceNo - a.sequenceNo;
        });
        let newScore = a.brands.pop();
        let newName =
          newScore.name + ' Scenario ' + this.alpha[alphIndex] + '' + ': ';
        let scenarioName = scenario;
        const tempTotal = Math.round(parseFloat(newScore.totalValue));
        const tempTotalPercent = Math.round(
          parseFloat(newScore.totalValueScorePercent)
        );
        SummaryformattedObject.country.push({
          name: a.country,
          percentage:
            tempTotalPercent > 0 ? tempTotalPercent.toString() + '%' : 'NA',
          secondaryPercentage: tempTotalPercent > 0 ? tempTotalPercent : 'NA',
          zIndex: tempTotalPercent > 0 ? tempTotalPercent : 0,
        });

        switch (this.viewMode) {
          case 'AbsoluteValue':
            formattedObject[a.country] =
              tempTotal > 0 ? tempTotal.toString() : 'NA';
            break;
          case 'Percentage':
            formattedObject[a.country] =
              tempTotalPercent > 0 ? tempTotalPercent.toString() + '%' : 'NA';
            break;
          default:
            formattedObject[a.country] =
              tempTotal > 0 ? tempTotal.toString() : 'NA';
            break;
        }
        if (!this.analogues.some((value: any) => value === scenarioName)) {
          this.analogues.push(scenarioName);
          newScore.position = currentIndex;
          newScore.name = scenarioName;
          SummaryformattedObject.brandedInnName = newName;
          SummaryformattedObject.scenarioName = scenarioName;
          SummaryformattedObject['inn'] = CapitalFirstLetter(newScore.inn);
          formattedObject.brandedInnName = newName + scenarioName;
          formattedObject['inn'] = CapitalFirstLetter(newScore.inn);
          this.analoguesData.push(SummaryformattedObject);
          this.gridFormattedData.push(formattedObject);
        }
      });
      this.analoguesData.forEach((element) => {
        element.country = this.compareValues(element.country);
      });
      this.analoguesData.sort((x: any, y: any) => x.position - y.position);
      this.gridFormattedData.sort((a: any, b: any) => {
        return a.position - b.position;
      });
      this.dataSource = new MatTableDataSource(this.gridFormattedData);
    });
  }


  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }

  public gridToggle(data: string): void {
    if (data === 'grid') {
      this.isGridClicked = true;
      this.isChartClicked = false;
      this.fileType = 'grid';
    } else if (data === 'chart') {
      this.isChartClicked = true;
      this.isGridClicked = false;
      this.fileType = 'chart';
    } else {
      return;
    }
  }

  public changeView(event: string): void {
    this.viewMode = event;
    this.initChartData();
  }
  public dwonloadFileCtry(): void {
    switch (this.fileType) {
      case 'chart':
        this.downloadAsPdf();
        break;
      case 'grid':
        this.CSVDownloadCntry?.downloadData();
        break;
    }
  }

  public dwonloadAssetTPPSummary(): void {
      this.summmaryAssetTpp?.exportToCsv()
  }

  public downloadAsPdf(): void {
    html2canvas(this.dataToExport.nativeElement).then((canvas) => {
      this.canvas.nativeElement.src = canvas.toDataURL();
      this.downloadLink.nativeElement.href = canvas.toDataURL('image/png');
      this.downloadLink.nativeElement.download = this.chartType + '.png';
      this.downloadLink.nativeElement.click();
    });
  }

  public subGridCountChanged(count: number): void {
    this.subGridRecordCount = count;
  }

  public downloadFile():void{
    this.CotForecastComponent.downloadFile()
  }
}
