import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, map } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { ValueScoreInput } from '../../../horizon-project/models/ValueScoreInput';
import { DomainEnum, ValueScoringKeyEnum } from '../models/value-scoring.enum';
import { CapitalFirstLetter } from '../../../horizon-project/utility-functions/StringFormatter';
import { SharedService } from 'projects/helios-gui/src/uikit/service/shared.service';
@Injectable({
  providedIn: 'root',
})
export class ValueScoringService {
  readonly projectAPIUri: string = environment.gatewayApiEndpointHorizon;

  public totalValueScoreByCountryDataLoading: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient, private sharedservice:SharedService) {}

  getValueScoringData(projectId: string, country: string, scenario: string) {
    return new Promise((resolve, reject) => {
      this.http
        .post(this.projectAPIUri + '/ValueAttributeScore/AssetValueScores', {
          projectId,
          country,
          scenario,
        })
        .subscribe({
          next: (data) => resolve(data),
          error: (err) => reject(err),
        });
    });
  }

  // getTotalValueScoreByCountries(
  //   projectId: string,
  //   scenario: string
  // ): Promise<any> {
  //   return new Promise((resolve, reject) => {
  //     this.totalValueScoreByCountryDataLoading.next(true);
  //     this.http
  //       .get(
  //         `${this.projectAPIUri}/ValueAttributeScore/TotalValuescoreByCountries?projectId=${projectId}&scenario=${scenario}`
  //       )
  //       .subscribe({
  //         next: (data) => {
  //           resolve(data);
  //           this.totalValueScoreByCountryDataLoading.next(false);
  //         },
  //         error: (err) => {
  //           reject(err);
  //         },
  //       });
  //   });
  // }


  getTotalValueScoreByCountries(
    projectId: string,
    scenario: string
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      this.totalValueScoreByCountryDataLoading.next(true);
      if (!this.sharedservice.isTotalValuescoreByCountriesCall$.value) {       
        this.http
          .get(
            `${this.projectAPIUri}/ValueAttributeScore/TotalValuescoreByCountries?projectId=${projectId}&scenario=${scenario}`
          )
          .subscribe({
            next: (data) => {           
              resolve(data);
              this.totalValueScoreByCountryDataLoading.next(false);
            },
            error: (err) => {
              reject(err);
            },
          });
      }
    });
  }
  
  getDynamicTotalValueScores(ValueScoreInput: ValueScoreInput[]) {
    return new Promise((resolve, reject) => {
      this.http
        .post(
          `${this.projectAPIUri}/ValuePricing/DynamicTotalValueScores`,
          ValueScoreInput
        )
        .subscribe({
          next: (data) => resolve(data),
          error: (err) => reject(err),
        });
    });
  }

  ConvertToCSV(objArray: any, headerList: string[], colums: any) {
    let str = '';
    const check =
      "'brandedInnName', 'totalValueScore','burdonDomainValueScore', 'productDomainValueScore', 'trialDomainValueScore', 'clinicalDomainValueScore', 'otherDomainValueScore', 'htaLink'";
    const maincol = [
      'Burden',
      'Product characteristics',
      'Trial design',
      'Clinical benefit',
      'Other',
    ];
    const arrayVal0 =
      typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    let row0 = 'S.No,';
    const tableIndex = 0;
    const fixedColumn = ['brandedInnName', 'totalValueScore'];

    for (const index in headerList) {
      if (headerList[index] !== null) {
        row0 += headerList[index] + ',';
      }
    }
    maincol.forEach((column: any, index: number) => {
      fixedColumn.push(headerList[index]);
      if (colums[column] != undefined) {
        colums[column]?.forEach((subColumn: any) => {
          fixedColumn.push(subColumn.name);
        });
      }
    });
    fixedColumn.push('htaLink');
    row0 = 'Brand Name,Total Value Score,';
    str += row0 + '\r\n';
    let line = tableIndex + 1 + '';
    line += ',,,';
    maincol.forEach((column: any, index: number) => {
      line += column + ',';
      if (colums[column] != undefined) {
        colums[column].forEach((subColumn: any) => {
          line += ',';
        });
      }
    });
    line = line.slice(2);
    str += line + '\r\n';
    line = tableIndex + 1 + '';
    line += ',,,';
    maincol.forEach((column: any, index: number) => {
      line += ',';
      if (colums[column] != undefined) {
        colums[column].forEach((subColumn: any) => {
          line += ValueScoringKeyEnum[subColumn.weight] + ',';
        });
      }
    });
    line = line.slice(2);
    str += line + '\r\n';
    line = tableIndex + 1 + '';
    line += ',,,';
    maincol.forEach((column: any, index: number) => {
      line += 'Domain Value Score,';
      if (colums[column] != undefined) {
        colums[column].forEach((subColumn: any) => {
          line += subColumn.name + ',';
        });
      }
    });
    line += 'link';
    line = line.slice(2);
    str += line + '\r\n';
    for (let i = 0; i < arrayVal0.length; i++) {
      line = tableIndex + 1 + '';
      for (const index in fixedColumn) {
        if (fixedColumn[index] !== null) {
          const head = fixedColumn[index];
          if (check.includes(head)) {
            if (head === 'htaLink') {
              line +=
                ',' +
                (arrayVal0[i][head] !== null
                  ? arrayVal0[i][head].replace('\r\n', '')
                  : '');
            } else {
              line +=
                ',' +
                (arrayVal0[i][head] !== undefined ? arrayVal0[i][head] : 'NA');
            }
          } else {
            line +=
              ',' +
              (arrayVal0[i][head] !== undefined
                ? arrayVal0[i][head].score
                : 'NA');
          }
        }
      }
      line = line.slice(2);
      str += line + '\r\n';
    }
    // str += '\r\n';
    // const legendsValue = 'Keys:,Not Considered,Limited Importance,Low Importance,Medium Importance,High Importance,Key driver';
    // str += legendsValue;
    return str;
  }

  downloadFile(data: any, filename = 'data', colums: any) {
    let csvData = '';
    const arrayVal0 = typeof data !== 'object' ? JSON.parse(data) : data;
    csvData = this.ConvertToCSV(
      data,
      [
        'burdonDomainValueScore',
        'productDomainValueScore',
        'trialDomainValueScore',
        'clinicalDomainValueScore',
        'otherDomainValueScore',
      ],
      colums
    );
    const blob = new Blob(['\ufeff' + csvData], {
      type: 'text/csv;charset=utf-8;',
    });
    const dwldLink = document.createElement('a');
    const url = URL.createObjectURL(blob);
    dwldLink.setAttribute('href', url);
    dwldLink.setAttribute('download', filename + '.csv');
    dwldLink.style.visibility = 'hidden';
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  public newDownloadFile(
    projectId: any,
    country: any,
    scenario: any,
    averageEnabled: any
  ): Observable<any> {
    let body = {
      projectId: projectId,
      country: country,
      scenario: scenario,
      averageEnabled: averageEnabled,
    };
    return this.http
      .post<any>(
        `${this.projectAPIUri}/ValueAttributeScore/AssetValueScores`,
        body
      )
      .pipe(
        map((analogue: any | null) => {
          return analogue.assetTLIs
            .map((res: any) => {
              return {
                brandName: res.brandName,
                innName: res.innName,
                sequenceNo: res.sequenceNo,
                totalValueScore: res.totalValueScore,
                valueDomains: res.valueDomains
                  .map((valueDomain: any) => {
                    return {
                      domainName: valueDomain.domainName,
                      domainValueScore: valueDomain.domainValueScore,
                      valueAttributes: valueDomain.valueAttributes.map(
                        (valueAttribute: any) => {
                          return {
                            valueAttributeName:
                              valueAttribute.valueAttributeName,
                            input: valueAttribute.input,
                            score: valueAttribute.score,
                            weight: valueAttribute.weight,
                            htaInfo: valueAttribute.htaInfo,
                          };
                        }
                      ),
                    };
                  })
                  .sort(
                    (a: any, b: any) =>
                      (DomainEnum[a.domainName] as unknown as number) -
                      (DomainEnum[b.domainName] as unknown as number)
                  ),
              };
            })
            .sort((a: any, b: any) => a.sequenceNo - b.sequenceNo);
        })
      );
  }

  public newDownloadFileValueTab(
    data: any
  ): any {
    return data.assetTLIs.map((res:any)=>{
      return {
        brandName: res.brandName,
        innName: res.innName,
        sequenceNo: res.sequenceNo,
        totalValueScore: res.totalValueScore,
        valueDomains: res.valueDomains.map((valueDomain: any) => {
          return {
            domainName: valueDomain.domainName,
            domainValueScore: valueDomain.domainValueScore,
            valueAttributes: valueDomain.valueAttributes.map(
              (valueAttribute: any) => {
                return {
                  valueAttributeName:
                    valueAttribute.valueAttributeName,
                  input: valueAttribute.input,
                  score: valueAttribute.score,
                  weight: valueAttribute.weight,
                  htaInfo: valueAttribute.htaInfo,
                };
              }
            ),
          };
        }).sort(
          (a: any, b: any) =>
            (DomainEnum[a.domainName] as unknown as number) -
            (DomainEnum[b.domainName] as unknown as number)
        ),
      }
    }).sort((a: any, b: any) => a.sequenceNo - b.sequenceNo);

  }

  newConvertToCSV(analogues: any, filename: string): void {
    let str = '';
    str += 'Brand Name,INN,Total Value Score,\r\n';
    let line1 = ',,,';
    let line2 = ',,,';
    let line3 = ',,,';
    let line4 = ',,,';
    let line5 = '';

    let valueDomainNames: any[] = [];
    analogues.forEach((analogue: any, index: number) => {
      analogue.valueDomains.forEach((valueDomain: any, domainIndex: number) => {
        if (index == 0) {
          valueDomainNames[valueDomain.domainName] = [];
        }
        valueDomain.valueAttributes.forEach(
          (valueAttribute: any, valueAttributeIndex: number) => {
            if (
              !valueDomainNames[valueDomain.domainName].some(
                (x: any) => x === valueAttribute.valueAttributeName
              )
            ) {
              valueDomainNames[valueDomain.domainName].push(
                valueAttribute.valueAttributeName
              );
            }
          }
        );
      });
    });
    analogues.forEach((analogue: any, index: number) => {
      let line6 = ',,';
      (line5 +=
        analogue.brandName +
        ',' +
        CapitalFirstLetter(analogue.innName) +
        ',' +
        analogue.totalValueScore +
        ','),
        analogue.valueDomains.forEach(
          (valueDomain: any, domainIndex: number) => {
            if (index == 0) {
              line1 += valueDomain.domainName + ',';
              line2 += ',';
              line3 += 'Domain Value Score,';
              line4 += ',';
            }
            line5 += valueDomain.domainValueScore + ',';
            line6 += ',';
            valueDomainNames[valueDomain.domainName].forEach(
              (attributeNam: any) => {
                let newIndex = valueDomain.valueAttributes.findIndex(
                  (x: any) => x.valueAttributeName === attributeNam
                );
                if (newIndex >= 0) {
                  if (index == 0) {
                    line1 += ',,,';
                    // line2 +=
                    //   ValueScoringKeyEnum[
                    //     valueDomain.valueAttributes[newIndex].weight
                      // ] + ',,,';
                      if(valueDomain.valueAttributes[newIndex].weight <= 0.01){
                        line2 += 'Not Considered' + ',,,';
                      }else if (valueDomain.valueAttributes[newIndex].weight >= 0.01 && valueDomain.valueAttributes[newIndex].weight <= 1.25) {
                        line2 += 'Limited Importance' + ',,,';
                      } else if (valueDomain.valueAttributes[newIndex].weight >= 1.26 && valueDomain.valueAttributes[newIndex].weight <= 2.5) {
                        line2 += 'Low Importance' + ',,,';
                      } else if (valueDomain.valueAttributes[newIndex].weight >= 2.51 && valueDomain.valueAttributes[newIndex].weight <= 3.75) {
                        line2 += 'Medium Importance' + ',,,';
                      } else if (valueDomain.valueAttributes[newIndex].weight >= 3.76 && valueDomain.valueAttributes[newIndex].weight <= 5.0) {
                        line2 += 'High Importance' + ',,,';
                      } else {
                        line2 += 'Key driver' + ',,,';
                      }
                    // attributeName.push(valueDomain.valueAttributes[newIndex].valueAttributeName);

                    line3 += attributeNam + ',,,';
                    line4 += 'Input,Score,Reference,';
                  }
                  line6 += ',,';
                  line5 +=
                    '"' +
                    (valueDomain.valueAttributes[newIndex].input !== null
                      ? valueDomain.valueAttributes[newIndex].input.replaceAll(
                          '"',
                          '""'
                        )
                      : 'NA') +
                    '",' +
                    valueDomain.valueAttributes[newIndex].score +
                    ',';
                  if (valueDomain.valueAttributes[newIndex].htaInfo != null) {
                    valueDomain.valueAttributes[newIndex].htaInfo.forEach(
                      (htaInfo: any, htaInfoIndex: number) => {
                        if (htaInfoIndex === 0) {
                          line5 += htaInfo.link + ',';
                        } else {
                          if (valueDomain.valueAttributes[newIndex] == 0) {
                            line6 += htaInfo.link + ',';
                          } else {
                          }
                        }
                      }
                    );
                  } else {
                    line5 += 'NA,';
                  }
                } else {
                  if (index == 0) {
                    line1 += ',,,';
                    line2 += ValueScoringKeyEnum[0] + ',,,';
                    line3 += attributeNam + ',,,';
                    line4 += 'Input,Score,Reference,';
                  }
                  line6 += ',,';
                  line5 += '"' + 'NA' + '",' + 'NA' + ',';
                  line5 += 'NA,';
                }
              }
            );
          }
        );
      line5 += '\r\n';
    });

    str += line1 + '\r\n';
    str += line2 + '\r\n';
    str += line3 + '\r\n';
    str += line4 + '\r\n';
    str += line5;

    const blob = new Blob(['\ufeff' + str], {
      type: 'text/csv;charset=utf-8;',
    });
    const dwldLink = document.createElement('a');
    const url = URL.createObjectURL(blob);
    dwldLink.setAttribute('href', url);
    dwldLink.setAttribute('download', filename + '.csv');
    dwldLink.style.visibility = 'hidden';
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  getValueSummary(projectId: string, country: string) {
    return new Promise((resolve, reject) => {
      this.http
        .get(
          `${this.projectAPIUri}/Summary/GetValueSummary?projectId=${projectId}&&country=${country}`
        )
        .subscribe({
          next: (data) => resolve(data),
          error: (err) => reject(err),
        });
    });
  }
}
