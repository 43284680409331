import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, take, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from 'projects/helios-gui/src/environments/environment';
import { CurrencyPipe } from '@angular/common';
import { HeaderService } from '../../../shared/services/header.service';

@Injectable({
  providedIn: 'root',
})
export class SummaryService {
  constructor(private http: HttpClient, private currency: CurrencyPipe,private headerService:HeaderService) {}

  readonly projectAPIUri: string = environment.gatewayApiEndpointHorizon;

  getAssetScoreSummary(projectId: string): Promise<any[]> {
    return new Promise<any[]>((resolve, reject) => {
      this.http
        .get<any[]>(
          `${this.projectAPIUri}/Summary/GetAssetScoreSummary?projectId=${projectId}`,
          {
            headers: {
              'Cache-Control':
                'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
              Pragma: 'no-cache',
              Expires: '0',
            },
          }
        )
        .subscribe({
          next: (data) => resolve(data),
          error: (err) => reject(err),
        });
    });
  }

  public getAssetValueScoreSummary(projectId: string): Observable<any> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('projectId', projectId);
    const urlString = new URL(
      `${this.projectAPIUri}/Summary/GetAssetTotalValueScoreSummary`
    );
    return this.http
      .get<any[]>(urlString.href, {
        params: queryParams,
        headers: {
          'Cache-Control':
            'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
          Pragma: 'no-cache',
          Expires: '0',
        },
      })
      .pipe(
        map((data: any) => {
          return data.data.map((asset: any, index: number) => {
            return {
              scenario: asset.scenario,
              sequenceNo: asset.scenarioSequence,
              data: asset.assetTotalValueScore,
            };
          });
        })
      );
  }
  public getCOTSummary(projectId: string): Observable<any> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('projectId', projectId);
    const urlString = new URL(
      `${this.projectAPIUri}/Summary/GetCotForecastSummary`
    );
    return this.http.get<any[]>(urlString.href, {
      params: queryParams,
      headers: {
        'Cache-Control':
          'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
        Pragma: 'no-cache',
        Expires: '0',
      },
    });
  }

  public newConvertToCSV(
    scenarios: any[],
    counries: any[],
    filename: string,
    currencySymbol: string
  ): void {
    let str = '';
    str += ',';
    counries.forEach((country) => {
      str += country.name + ',' + 'Price Info'+',';
    });
    str += '\r\n';
    // str += ',';
    // counries.forEach((country) => {
    //   str += country.info + ',';
    // });
    // str += '\r\n';
    scenarios.forEach((scenario) => {
      str += scenario.scenarioIndexName + scenario.scenario + '\r\n';
      scenario.priceTypes.forEach((priceType: any) => {
        str += priceType.priceType + ',';
        priceType.country.forEach((country: any) => {
          if(this.headerService.ComparatorOrAnalog.value === 'Analogues'){
            if (country.price <= 0) {
              str += 'NA' + ','+ '"' +country.info+'"' + ',' ;
            } else {
              if(country.price != '******'){
                str +=
                '"' +
                this.currency.transform(
                  country.price,
                  country.currency,
                  'symbol-narrow',
                  '1.0-2'
                ) +
                '"'+ ',' + '"' +country.info+'"' + ',';
              }else{
                str +=
               
                country.price +',' + '"' +country.info+'"' + ',';
              }
            }
          }else{
            if( country.minprice!= country.maxprice){
              if(country.minprice != '******'){
                str +=
                '"' +
                this.currency.transform(
                  country.minprice,
                  country.currency,
                  'symbol-narrow',
                  '1.0-2'
                ) 
                + ' to '+ 
                  this.currency.transform(
                    country.maxprice,
                    country.currency,
                    'symbol-narrow',
                    '1.0-2'
                  ) + '"'+ ',' + '"' +country.info+'"' + ',';

              }else{
                str +=
                country.minprice
                + ',' + '"' +country.info+'"' + ',';
              }
         
            }else{
              if(country.minprice != '******'){
                str +=
                '"' +
                this.currency.transform(
                  country.minprice,
                  country.currency,
                  'symbol-narrow',
                  '1.0-2'
                ) +
                '"'+ ',' + '"' +country.info+'"' + ',';
              }else{
                str +=
                
                country.minprice +
               ',' + '"' +country.info+'"' + ',';
              }
  
            }
          }
 
        });
        str += '\r\n';
      });
    });

    const blob = new Blob(['\ufeff' + str], {
      type: 'text/csv;charset=utf-8;',
    });
    const dwldLink = document.createElement('a');
    const url = URL.createObjectURL(blob);
    dwldLink.setAttribute('href', url);
    dwldLink.setAttribute('download', filename + '.csv');
    dwldLink.style.visibility = 'hidden';
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }
}
