<div
  [ngClass]="{
    expanded: !isHistoryCollapsed,
    collapsed: isHistoryCollapsed
  }"
  class="he-container he-py-2 he-px-4 he-flex he-flex-col"
  class="he-container he-py-2 he-px-4 he-flex he-flex-col"
>
  <!-- Header -->
  <div
    [ngClass]="{
      'he-flex-col': isHistoryCollapsed,
      'he-justify-end': page != 'gpi-chat',
      'he-justify-between': page == 'gpi-chat'
    }"
    class="he-flex he-items-center he-mb-4 he-chat-new-chat he-border-bottom"
  >
    <div
      *ngIf="page == 'gpi-chat'"
      class="he-flex he-items-center he-gap-2 he-text-sm he-font-medium he-py-2 he-new-chat-button"
      (click)="newChat()"
    >
      <span class="he-rounded-full">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21.2812 12C21.2812 6.8805 17.1195 2.715 12.0037 2.715C6.88424 2.715 2.71874 6.8805 2.71874 12C2.71874 13.7752 3.21224 15.48 4.14974 16.9545L2.77649 20.2462C2.72286 20.3735 2.70551 20.5132 2.72633 20.6497C2.74716 20.7862 2.80535 20.9143 2.89448 21.0199C2.98361 21.1254 3.10019 21.2042 3.23132 21.2475C3.36246 21.2909 3.50303 21.2971 3.63749 21.2655L7.83674 20.2972C9.13016 20.9463 10.5573 21.2843 12.0045 21.2842C17.1195 21.285 21.2812 17.1195 21.2812 12ZM12.0037 19.785C10.707 19.785 9.42524 19.4602 8.29724 18.846C8.13612 18.7578 7.9482 18.7319 7.76924 18.7732L4.72274 19.476L5.68799 17.1615C5.73669 17.0448 5.75508 16.9176 5.74144 16.7918C5.72781 16.6661 5.68259 16.5458 5.60999 16.4422C4.69914 15.1404 4.21318 13.5888 4.21874 12C4.21874 7.707 7.71074 4.215 12.0037 4.215C16.2922 4.215 19.7812 7.707 19.7812 12C19.7812 16.293 16.2922 19.785 12.0037 19.785Z"
            fill="currentColor"
          />
          <path
            d="M15 11.25H12.75V9C12.75 8.80109 12.671 8.61032 12.5303 8.46967C12.3897 8.32902 12.1989 8.25 12 8.25C11.8011 8.25 11.6103 8.32902 11.4697 8.46967C11.329 8.61032 11.25 8.80109 11.25 9V11.25H9C8.80109 11.25 8.61032 11.329 8.46967 11.4697C8.32902 11.6103 8.25 11.8011 8.25 12C8.25 12.1989 8.32902 12.3897 8.46967 12.5303C8.61032 12.671 8.80109 12.75 9 12.75H11.25V15C11.25 15.1989 11.329 15.3897 11.4697 15.5303C11.6103 15.671 11.8011 15.75 12 15.75C12.1989 15.75 12.3897 15.671 12.5303 15.5303C12.671 15.3897 12.75 15.1989 12.75 15V12.75H15C15.1989 12.75 15.3897 12.671 15.5303 12.5303C15.671 12.3897 15.75 12.1989 15.75 12C15.75 11.8011 15.671 11.6103 15.5303 11.4697C15.3897 11.329 15.1989 11.25 15 11.25Z"
            fill="currentColor"
          />
        </svg>
      </span>
      <span class="full-content">New Chat</span>
    </div>

    <div (click)="toggleHistory()" class="he-p-1">
      <ng-container *ngIf="isHistoryCollapsed">
        <i class="he-expand-icon">
          <svg
            width="29"
            height="29"
            viewBox="0 0 29 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18.4458 24.6437H11.1542C7.78177 24.6437 4.95625 21.9094 4.95625 18.4458V11.1542C4.95625 7.78176 7.69062 4.95624 11.1542 4.95624H18.4458C21.8182 4.95624 24.6437 7.69061 24.6437 11.1542V18.4458C24.6437 21.8182 21.8182 24.6437 18.4458 24.6437ZM11.1542 6.41457C8.51093 6.41457 6.41458 8.51093 6.41458 11.1542V18.4458C6.41458 21.0891 8.60208 23.2766 11.2453 23.2766H18.537C21.1802 23.2766 23.3677 21.0891 23.3677 18.4458V11.1542C23.3677 8.51093 21.1802 6.32343 18.537 6.32343H11.1542V6.41457Z"
              fill="currentColor"
            />
            <path
              d="M19.3573 14.6177C18.9927 14.6177 18.6281 14.3442 18.6281 13.8885V10.9719H15.7115C15.3469 10.9719 14.9823 10.6984 14.9823 10.2427C14.9823 9.78696 15.2557 9.51352 15.7115 9.51352H18.9016C19.5396 9.51352 20.0865 10.0604 20.0865 10.6984V13.8885C20.0865 14.2531 19.7219 14.6177 19.3573 14.6177Z"
              fill="currentColor"
            />
            <path
              d="M15.7115 14.6177C15.5292 14.6177 15.3469 14.5266 15.2557 14.4354C14.9823 14.162 14.9823 13.7062 15.2557 13.4328L18.4458 10.2427C18.7193 9.96927 19.175 9.96927 19.4484 10.2427C19.7219 10.5161 19.7219 10.9719 19.4484 11.2453L16.2583 14.4354C16.076 14.5266 15.8937 14.6177 15.7115 14.6177ZM13.8885 20.0865H10.6984C10.0604 20.0865 9.51353 19.5396 9.51353 18.9016V15.7115C9.51353 15.3469 9.78697 14.9823 10.2427 14.9823C10.6984 14.9823 10.9719 15.2557 10.9719 15.7115V18.7193H13.8885C14.2531 18.7193 14.6177 18.9927 14.6177 19.4484C14.6177 19.9042 14.2531 20.0865 13.8885 20.0865Z"
              fill="currentColor"
            />
            <path
              d="M10.6984 19.6307C10.5161 19.6307 10.3339 19.5396 10.2427 19.4484C9.96927 19.175 9.96927 18.7192 10.2427 18.4458L13.4328 15.2557C13.7062 14.9823 14.162 14.9823 14.4354 15.2557C14.7089 15.5291 14.7089 15.9849 14.4354 16.2583L11.1542 19.3573C11.063 19.5396 10.8807 19.6307 10.6984 19.6307Z"
              fill="currentColor"
            />
          </svg>
        </i>
      </ng-container>
      <ng-container *ngIf="!isHistoryCollapsed">
        <i class="he-collapse-icon">
          <svg
            width="27"
            height="27"
            viewBox="0 0 27 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.4458 23.0438H10.1541C6.7817 23.0438 3.95618 20.3094 3.95618 16.8458V9.55418C3.95618 6.18178 6.69055 3.35626 10.1541 3.35626H17.4458C20.8182 3.35626 23.6437 6.09064 23.6437 9.55418V16.8458C23.6437 20.2182 20.8182 23.0438 17.4458 23.0438ZM10.1541 4.8146C7.51086 4.8146 5.41451 6.91095 5.41451 9.55418V16.8458C5.41451 19.4891 7.60201 21.6766 10.2452 21.6766H17.5369C20.1801 21.6766 22.3676 19.4891 22.3676 16.8458V9.55418C22.3676 6.91095 20.1801 4.72345 17.5369 4.72345H10.1541V4.8146Z"
              fill="currentColor"
            />
            <path
              d="M15.1691 7.11152C15.6228 7.11152 16.0765 7.4518 16.0765 8.01893L16.0765 11.6486L19.7061 11.6486C20.1598 11.6486 20.6135 11.9888 20.6135 12.556C20.6135 13.1231 20.2732 13.4634 19.7061 13.4634L15.7362 13.4634C14.9422 13.4634 14.2617 12.7828 14.2617 11.9888L14.2617 8.01893C14.2617 7.56523 14.7154 7.11152 15.1691 7.11152Z"
              fill="currentColor"
            />
            <path
              d="M20.018 6.85629C20.2448 6.85629 20.4717 6.96971 20.5851 7.08314C20.9254 7.42341 20.9254 7.99054 20.5851 8.33082L16.6152 12.3007C16.2749 12.641 15.7078 12.641 15.3675 12.3007C15.0273 11.9605 15.0273 11.3933 15.3675 11.053L19.4509 7.19656C19.5643 6.96971 19.7911 6.85629 20.018 6.85629Z"
              fill="currentColor"
            />
            <path
              d="M13.1274 19.7869C12.6737 19.7869 12.22 19.4466 12.22 18.8795V15.2499H8.59039C8.13669 15.2499 7.68298 14.9096 7.68298 14.3425C7.68298 13.7753 8.02326 13.4351 8.59039 13.4351H12.5603C13.3543 13.4351 14.0348 14.1156 14.0348 14.9096V18.8795C14.0348 19.3332 13.5811 19.7869 13.1274 19.7869Z"
              fill="currentColor"
            />
            <path
              d="M8.27851 20.0422C8.05166 20.0422 7.82481 19.9288 7.71139 19.8153C7.37111 19.4751 7.37111 18.9079 7.71139 18.5676L11.6813 14.5977C12.0216 14.2575 12.5887 14.2575 12.929 14.5977C13.2693 14.938 13.2693 15.5051 12.929 15.8454L8.84564 19.7019C8.73222 19.9288 8.50537 20.0422 8.27851 20.0422Z"
              fill="currentColor"
            />
          </svg>
        </i>
      </ng-container>
    </div>
  </div>

  <!-- Chat Sections -->
  <ng-container *ngIf="!isHistoryCollapsed">
    <div class="he-space-y-6 he-overflow-auto he-flex-1 he-full-content">
      <!-- Section - Yesterday -->
      <div
        class="he-border-bottom"
        *ngFor="let section of getKeys(historyData)"
      >
        <span
          class="he-text-xs he-font-semibold he-chat-heading he-text-black-500 he-mb-2"
        >
          {{ section }}
        </span>
        <div class="">
          <div
            *ngFor="let item of historyData[section].reverse()"
            [class.active]="item.conversation_id == selectedConversationId"
            (click)="selectItem(item.conversation_id)"
            class="he-chat-item he-bg-white he-rounded-lg he-text-gray-600"
          >
            {{
              item.title.length > 30
                ? item.title.substring(0, 30) + "..."
                : item.title
            }}
          </div>
        </div>
      </div>
      <div *ngIf="loading">Loading..</div>
    </div>
  </ng-container>
</div>
