import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ElementRef,
  OnDestroy,
  ViewEncapsulation,
} from '@angular/core';
import * as echarts from 'echarts';
import { ExportImageService } from '../../../../shared/services/export-image.service';
import {
  Dropdown,
  HeaderService,
} from '../../../../shared/services/header.service';
import html2canvas from 'html2canvas';
import { AnalogueService } from '../../../../horizon-project/services/analogue.service';
import { Subject, takeUntil } from 'rxjs';
import { Capitalise } from '../../../../horizon-project/utility-functions/StringFormatter';
import { ActivatedRoute } from '@angular/router';
import { ColorSetService } from '../../../../shared/services/colorSet.service';
import { CapitalizeFirstPipe } from '../../../project/pipes/capitalizeFirst.pipe';
import { SharedService } from 'projects/helios-gui/src/uikit/service/shared.service';

@Component({
  selector: 'he-project-forecast-value-price',
  templateUrl: './project-forecast-value-price.component.html',
  styleUrls: ['./project-forecast-value-price.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class ProjectForecastValuePriceComponent
  implements OnInit, OnChanges, OnDestroy
{
  @ViewChild('dataToExport', { static: false }) public dataToExport:
    | ElementRef
    | any;
  @ViewChild('downloadLink') downloadLink: ElementRef | any;
  @ViewChild('canvas') canvas: ElementRef | any;
  @Input() chartData: any;
  @Input() assetData: any;
  @Input() trendLine: any;
  @Input() country: any;
  @Input() defaultBrands: any = [];
  @Input() minValueScore: any;
  @Input() totalValueScore: any;
  @Input() maxpredictedPrice: any;
  @Input() minpredictedPrice: any;
  @Input() selectedCurrency: string = '';
  @Input() infoData: any[] = [];
  @Input() selectedCurrencySymbol: string = '';
  @Input() selectedRoute: string = '';
public prieceLabel:string='VALUE-PRICE ANALYSIS'
  public brandList: any[] = [];
  public info: string = '';
  @Input() selectedPriceType = 'launchPrice';

  priceTypes: Dropdown[] = [];

  public chartOption!: echarts.EChartsOption;
  public updateChartOption!: echarts.EChartsOption;

  public echartsInstance!: echarts.ECharts;
  seriesData: any[] = [];

  public analogueData: any[] = [];

  sortingActive: boolean = false;

  legends: string[] = [];
  legendsHta: any[] = [];
  chartNameInput: any = 'VALUE-PRICE ANALYSIS';
  private unsubscriber$ = new Subject<void>();

  constructor(
    private headerService: HeaderService,
    private exportImageService: ExportImageService,
    private analogueService: AnalogueService,
    private route: ActivatedRoute,
    private headerservice: HeaderService,
    private colorSetService: ColorSetService,
    private capitalizeFirstPipe: CapitalizeFirstPipe,    
    private sharedService:SharedService
  ) {}

  resetGraph() {
    this.headerService.setSelectedBrands(this.defaultBrands);
  }
  ngOnInit(): void {
    this.headerService.ComparatorOrAnalog.pipe(
      takeUntil(this.unsubscriber$)
    ).subscribe((data: string) => {
      this.selectedRoute = data;
      if( this.selectedRoute === 'Comparators'){
        this.prieceLabel = 'PRICE CORRIDOR ANALYSIS'
        }else{
          this.prieceLabel = 'VALUE-PRICE ANALYSIS'
        }
    });
    //this.refreshChart();
    this.route.queryParams
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(async (f) => {
        await this.initializeCompData();
        this.initChartOptions();
        this.priceTypes = this.headerService.priceTypes;
        this.priceTypes.filter((x) => x.value === 'netPrice')[0].visible =
          this.headerService.netPricePermission.isNetPriceAvailable;
        this.priceTypes.filter((x) => x.value === 'netPrice')[0].disabled =
          !this.headerService.netPricePermission.isNetPriceVisible;
        this.priceTypes.sort((a, b) => a.id - b.id);
      });

    this.analogueService.castAnalogueGridData
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((data) => {
        this.analogueData = data;
      });

    // this.headerService.onPriceTypeChanged
    //   .pipe(takeUntil(this.unsubscriber$))
    //   .subscribe((priceType) => {
    //     this.selectedPriceType = priceType;
    //     console.log(1,this.selectedPriceType)
    //   });
  }

  private async initializeCompData() {
    //this.legends = [];
    this.seriesData = [];
    this.analogueData = [];
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.refreshChart();
    this.setInfoData();
  }

  public setInfoData(): void {
    let price = this.priceTypes.filter(
      (x) => x.value === this.selectedPriceType
    )[0].viewValue;
    if (this.infoData.length > 0) {
      this.info = this.infoData.filter(
        (x: any) => x.priceType === price
      )[0].infoIconData;
    }
  }

  private initChartOptions() {
    this.chartOption = {
      backgroundColor: '#ffffff',
      tooltip: {
        formatter(params: any) {
          return [].join('<br/>');
        },
      },
      legend: {
        orient: 'vertical',
        left: 0,
        icon: 'circle',
        data: [],
        selectedMode: false,
        padding: [25, 0],
      },
      grid: {
        left: '25%',
        right: '20%',
        bottom: '20%',
        top: '5%',
        containLabel: true,
      },
      xAxis: {
        name: 'VALUE SCORES',
        nameLocation: 'middle',
        nameTextStyle: { fontWeight: 500, color: '#000000' },
        nameGap: 50,
        type: 'value',
        min: this.minValueScore,
        scale: true,
        axisLabel: {
          formatter: '{value}',
          color: '#4D488C',
        },
        splitLine: {
          show: false,
        },
      },
      yAxis: {
        name: `${this.selectedCurrencySymbol}, COST OF TREATMENT`,
        nameLocation: 'middle',
        nameTextStyle: { fontWeight: 500, color: '#000000' },
        nameGap: 80,
        type: 'value',
        min: 0,
        axisLabel: {
          formatter: '{value}',
          color: '#4D488C',
        },
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
      },
      series: [],
    };
  }

  async refreshChart() {
    this.seriesData = [];
    this.legends = [];
    this.legendsHta = [];
    this.chartOption = {};
    this.updateChartOption = {};

    const assetData = this.assetData;
    const country = this.country;

    if (this.analogueData) {
      let count = 0;
      this.analogueData.forEach((item: any) => {
        if (item.position !== null) {
          count = count + 1;
        }
      });
      if (count == this.analogueData.length) {
        this.sortingActive = true;
      }
    }
    if (this.sortingActive) {
      await this.sortChartData();
    }
    let brandList: any[] = [];
    if (Array.isArray(this.chartData)) {
      for (let i = 0; i < this.chartData.length; i++) {
        const item = this.chartData[i];
        const currencySymbol = this.selectedCurrencySymbol;
        if (!brandList.some((x) => x.name === item.name)) {
          brandList.push(item);
          item.color = this.colorSetService.setColor(item.name);
        } else {
          item.color = brandList.filter((x) => x.name === item.name)[0].color;
        }
        this.brandList;
        this.seriesData.push({
          name: Capitalise(item.name),
          type: 'effectScatter',
          symbolSize: 10,
          data: [[item.totalValueScore, item.actualPrice]],
          showEffectOn: 'emphasis',
          tooltip: {
            formatter(params: any) {
              const data = params.data || [0, 0];
              return [
                '<b>' + params.seriesName.toString() + '</b>',
                country,
                'VS: ' + item.totalValueScore.toFixed(0),
                'COT: ' +
                  currencySymbol +
                  data[1]
                    .toFixed(0)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              ].join('<br/>');
            },
          },
          itemStyle: {
            color: item.color,
            width: 2,
            height: 2,
          },
        });
        this.legends.push(Capitalise(item.name));
        let part1 = ''
        if(item.htaIndication != null){
           part1 = this.splitStringByDynamicLength(this.capitalizeFirstPipe.transform(item.htaIndication))
        }
        this.legendsHta.push({
          name:Capitalise(item.name),
          tooltip:{
            show:item.htaIndication != null? true:false,
          formatter:part1,
          borderColor: brandList.filter((x) => x.name === item.name)[0].color,
          borderWidth: 1,
          position: function (point:any,) {
            // fixed at top
            return [point[0]-15, '1%']
        },
          }
        }
        )
      }
      const currencySymbol = this.selectedCurrencySymbol;
      if (this.selectedRoute === 'Analogues') {
        //asset scatter
        this.seriesData.push({
          name: 'AssetPoint',
          type: 'effectScatter',
          symbol: 'emptyCircle',
          symbolSize: 8,
          data: [
            [this.assetData?.totalValueScore, this.assetData?.predictedPrice],
          ],
          tooltip: {
            formatter(params: any) {
              const data = params.data || [0, 0];
              return [
                country,
                'VS: ' + data[0].toFixed(0),
                'COT: ' +
                  currencySymbol +
                  data[1]
                    .toFixed(0)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              ].join('<br/>');
            },
          },
          itemStyle: {
            color: '#716BC1',
            width: 2,
            height: 2,
            opacity: 0.47,
          },
        });
        // asset dot line
        this.seriesData.push({
          name: 'AssetPointLine1',
          type: 'line',
          showSymbol: false,
          lineStyle: { color: '#716BC1', width: 1, type: 'dashed' },
          data: [
            [0, this.assetData?.predictedPrice],
            [this.assetData?.totalValueScore, this.assetData?.predictedPrice],
          ],
          zlevel: 0,
        });
        // asset label
        this.seriesData.push({
          name: 'AssetPointLine1Label',
          type: 'scatter',
          symbolSize: 1,
          data: [[this.minValueScore, this.assetData?.predictedPrice]],
          label: {
            normal: {
              show: true,
              // position: ['50%', '50%'],
              align: 'left',
              formatter:
                currencySymbol +
                parseFloat(this.assetData?.predictedPrice)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              borderRadius: 15,
              padding: 8,
              backgroundColor: '#716BC1',
              color: '#ffffff',
              fontSize: 14,
              lineHeight: 18,
              fontWeight: 700,
              opacity: 1,
            },
          },
          zlevel: 2,
        });

        // trend line
        this.seriesData.push({
          name: 'TrendLine',
          type: 'line',
          showSymbol: false,
          lineStyle: { color: '#716BC1', width: 5 },
          data: [
            [this.trendLine[0]?.x, this.trendLine[0]?.y],
            [this.trendLine[1]?.x, this.trendLine[1]?.y],
          ],
        });
        // asset dot line vertical
        this.seriesData.push({
          name: 'AssetPointLine2',
          type: 'line',
          showSymbol: false,
          lineStyle: { color: '#716BC1', width: 1, type: 'dashed' },
          data: [
            [this.assetData?.totalValueScore, 0],
            [this.assetData?.totalValueScore, this.assetData?.predictedPrice],
          ],
          zlevel: 0,
        });
      } else {
        this.prieceLabel=brandList.length > 1?'PRICE CORRIDOR ANALYSIS':'PRICE BENCHMARK ANALYSIS'
        this.seriesData.push({
          name: 'ciling price line',
          type: 'line',
          showSymbol: false,
          lineStyle: { color:brandList.length > 1?'#2891a3':'#5c55c6', width: 1, type: 'solid' },
          data: [
            [0, this.maxpredictedPrice.price],
            [this.totalValueScore + 10, this.maxpredictedPrice.price],
          ],
          zlevel: 0,
        });
        this.seriesData.push({
          name: brandList.length > 1?'Ceiling price':'Price benchmark',
          type: 'scatter',
          symbolSize: 1,
          data: [[this.totalValueScore + 10 , this.maxpredictedPrice.price]],
          label: {
            normal: {
              show: true,
              align: 'left',
              formatter:
                currencySymbol +
                parseFloat(this.maxpredictedPrice.price)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              borderRadius: 15,
              padding: 8,
              backgroundColor:brandList.length > 1?'#2891a3':'#5c55c6' ,
              color: '#ffffff',
              fontSize: 14,
              lineHeight: 18,
              fontWeight: 700,
              opacity: 1,
            },
          },
          zlevel: 2,
          tooltip: {
            formatter(params: any) {
              const data = params.data || [0, 0];
              return [
                '<b>' +(brandList.length > 1?'Ceiling price':'Price benchmark')+ '</b>',
                 '' + currencySymbol +
                  data[1]
                    .toFixed(0)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              ].join('<br/>');
            },
          },
        });
        // asset dot line vertical
        this.seriesData.push({
          name: 'AssetPointLine2',
          type: 'line',
          showSymbol: false,
          lineStyle: { color: '#716BC1', width: 1, type: 'dashed' },
          data: [
            [this.assetData?.totalValueScore, 0],
            [this.assetData?.totalValueScore, this.maxpredictedPrice.price],
          ],
          zlevel: 0,
        });
        // asset dot line vertical
        this.seriesData.push({
          name: 'AssetPointLine2',
          type: 'line',
          showSymbol: false,
          lineStyle: { color: '#716BC1', width: 1, type: 'dashed' },
          data: [
            [this.assetData?.totalValueScore, 0],
            [this.assetData?.totalValueScore, this.maxpredictedPrice.price],
          ],
          zlevel: 0,
        });
        if (brandList.length > 1) {
          this.seriesData.push({
            name: 'floor price line',
            type: 'line',
            showSymbol: false,
            lineStyle: { color:'#5c55c6', width: 1, type: 'solid' },
            data: [
              [0, this.minpredictedPrice.price],
              [this.totalValueScore + 10, this.minpredictedPrice.price],
            ],
            zlevel: 0,
          });
          this.seriesData.push({
            name: 'Floor price',
            type: 'scatter',
            symbolSize: 1,
            data: [[this.totalValueScore + 10, this.minpredictedPrice.price]],
            label: {
              normal: {
                show: true,
                // position: ['50%', '50%'],
                align: 'left',
                formatter:
                  currencySymbol +
                  parseFloat(this.minpredictedPrice.price)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                borderRadius: 15,
                padding: 8,
                backgroundColor: '#5c55c6',
                color: '#ffffff',
                fontSize: 14,
                lineHeight: 18,
                fontWeight: 700,
                opacity: 1,
              },
            },
            zlevel: 3,
            tooltip: {
              formatter(params: any) {
                const data = params.data || [0, 0];
                return [
                  '<b>' +'Floor Price'+ '</b>',
                   '' + currencySymbol +
                    data[1]
                      .toFixed(0)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                ].join('<br/>');
              },
            },
          });
        }
      }

      // max value score
      this.seriesData.push({
        name: 'AssetPointLine1Labe2',
        type: 'scatter',
        symbolSize: 1,
        data: [[this.assetData?.totalValueScore, 0]],
        label: {
          normal: {
            show: true,
            formatter: parseFloat(this.assetData?.totalValueScore).toFixed(0),
            borderRadius: 15,
            padding: 8,
            backgroundColor: '#374050',
            color: '#ffffff',
            fontSize: 14,
            lineHeight: 18,
            fontWeight: 500,
            opacity: 1,
          },
        },
        zlevel: 2,
      });
    }
    const hta=this.legendsHta
    this.chartOption = {
      backgroundColor: '#ffffff',
      tooltip: {
        trigger: 'item',
        formatter(params: any) {
          return [assetData.name, country].join('<br/>');
        },
      },
      legend: [
      {
        orient: 'vertical',
        left: 0,
        type: 'scroll',
        icon: 'circle',
        data: [...this.legendsHta],
        selectedMode: this.legends.length <= 2 ? this.selectedRoute === 'Comparators'?'multiple' :'multiple' : 'multiple',
        padding: [25, 0],
        // tooltip:{
        //   show: true,
        // formatter: function (name){
        //  const tooltip= hta.find(x=>x.name === name).htaIndication
        // return tooltip;
        // }
        // }
      },
      {
       icon: 'sqaure',
        data: [{name:'Floor price',itemStyle: { color:'#5c55c6' } }, {name:brandList.length > 1?'Ceiling price':'Price benchmark',itemStyle: { color: brandList.length > 1?'#2891a3':'#5c55c6'} }],
        top: "-5",
        selectedMode: false,
      }
      ],
      grid: {
        left: '25%',
        right: '20%',
        bottom: '20%',
        top: '10%',
        containLabel: true,
      },
      xAxis: {
        name: 'VALUE SCORES',
        nameLocation: 'middle',
        nameTextStyle: { fontWeight: 500, color: '#000000' },
        nameGap: 50,
        type: 'value',
        min: this.minValueScore,
        scale: true,
        axisLabel: {
          formatter: '{value}',
          color: '#4D488C',
        },
        splitLine: {
          show: false,
        },
      },
      yAxis: {
        name: `${this.selectedCurrencySymbol}, COST OF TREATMENT`,
        nameLocation: 'middle',
        nameTextStyle: { fontWeight: 500, color: '#000000' },
        nameGap: 80,
        type: 'value',
        min: 0,
        axisLabel: {
          formatter: '{value}',
          color: '#4D488C',
        },
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
      },
      series: [...this.seriesData],
    };
    this.updateChartOption = JSON.parse(JSON.stringify(this.chartOption));
  }

  onLegendSelectChanged(params: any) {
    const brandsForRegression: string[] = [];
    this.legends.forEach((f) => {
      if (params.selected[f]) {
        brandsForRegression.push(f);
      }
    });
    this.headerService.setSelectedBrands(brandsForRegression);
    this.sharedService.isLoading$.next(false);
  }

  onChartInit(ec: any) {
    this.echartsInstance = ec;
    this.echartsInstance.on('legendselectchanged', (params: any) => {
      const brandsForRegression: string[] = [];
      this.legends.forEach((f) => {
        if (params.selected[f]) {
          brandsForRegression.push(f);
        }
      });

      ec.setOption(this.chartOption);

      this.headerService.setSelectedBrands(brandsForRegression);
    });
    this.exportImageService.registerApi(ec, this.chartNameInput);
  }

  dwonloadFile() {
    this.exportImageService.saveImage(this.chartNameInput, this.chartNameInput);
  }

  public downloadAsPdf(): void {
    html2canvas(this.dataToExport.nativeElement).then((canvas) => {
      this.canvas.nativeElement.src = canvas.toDataURL();
      this.downloadLink.nativeElement.href = canvas.toDataURL('image/png');
      this.downloadLink.nativeElement.download = this.chartNameInput + '.png';
      this.downloadLink.nativeElement.click();
    });
  }

  public async sortChartData() {
    if (this.chartData)
      this.chartData.sort((a: any, b: any) => {
        return (
          this.analogueData.filter(
            (x) => x.brandedInnName.toLowerCase() === a.name.toLowerCase()
          )[0]?.position -
          this.analogueData.filter(
            (x) => x.brandedInnName.toLowerCase() === b.name.toLowerCase()
          )[0]?.position
        );
      });
  }
  priceTypeChange($event: string) {         
    this.sharedService.isTotalValuescoreByCountriesCall$.next(true);
    this.sharedService.isBrandCotDataCall$.next(true);   
    this.headerService.setSelectedPriceType($event);
    this.setInfoData();
  }

  getSelectedViewValue(): string | undefined {
    const selected = this.priceTypes.find(pt => pt.value === this.selectedPriceType);
    return selected ? selected.viewValue : undefined;
  }
  splitStringByDynamicLength(input: string): string {
    const length = input.length;
    const sectionsCount = Math.ceil(length / 75);
    const partLength = Math.ceil(length / sectionsCount);
    let sections: string[] = [];

    for (let i = 0; i < sectionsCount; i++) {
        const start = i * partLength;
        const end = start + partLength;
        sections.push(input.slice(start, end));
    }
    let newstring = sections.join('<br>')
    return newstring;
}

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
