<mat-nav-list fxFlex *ngIf="sideBarOpen">
  <div
  
    class="flex-container"
    fxLayout="row"
    fxLayoutAlign="flex-start center"
    *heFeatureFlag="{ Name: 'create-project-btn', Page: 'common' }"
    
  >
    <a
      routerLinkActive="list-item-active"
      routerLink="/horizon/create-new-project"
      id="create-project-btn"
    >
      <button mat-button fxLayoutAlign="flex-end">
        <span class="button-title">{{ isDraftProject ? 'Open draft project' : 'Create new project'}}</span>
      </button>
    </a>
    <button mat-icon-button (click)="toggleSideBar()">
      <mat-icon>chevron_left</mat-icon>
    </button>
  </div>

  <mat-divider></mat-divider>
  <div *ngFor="let project of dataSource; let i = index" id="project-list-container">
    <a
      class="asset-link"
      mat-list-item
      routerLinkActive="list-item-active"
      [routerLink]="isLoading?null:'/horizon/list-projects'"
      [queryParams]="{ project: project.id }"
      [disabled]="isLoading"
      (click)="showNotification( project,i )"
      style="display: flex; justify-content: space-between;"
    >
    <div>
      <div style=" display: flex;">
        <span class="project-title"
        >{{ project.name }} </span> <br>
        <div class="version" *ngIf="project?.projectFrameworkUpgradeLogsDTO[0]?.decision">
          <span 
          >{{project.valueFramework_Version}}</span> 
        </div>
        <div class="version" *ngIf="project?.parentProjectId != null">
          <span 
          >{{project.valueFramework_Version}}</span> 
        </div>
      </div>
      <span class="light-text">{{
        project.modifiedOn==null? (project.createdOn | date : "dd/MM/yyyy") : (project.modifiedOn | date : "dd/MM/yyyy")
      }}</span>
    </div>
    

      <mat-icon class="project-title-icon" matTooltipClass="horizon-tooltip" matTooltip="Read" *ngIf="!project.permissions.project.edit && userEmailId !== project.ownerEmail">remove_red_eye</mat-icon>
      <mat-icon class="project-title-icon"  matTooltipClass="horizon-tooltip" matTooltip="Read and write" *ngIf="project.permissions.project.edit && userEmailId !== project.ownerEmail">edit</mat-icon>
      <mat-icon class="project-title-icon"  matTooltipClass="horizon-tooltip" matTooltip="Owner" *ngIf="userEmailId === project.ownerEmail">person</mat-icon>
    </a>
    <mat-divider></mat-divider>
  </div>
</mat-nav-list>
<mat-nav-list fxFlex *ngIf="!sideBarOpen">
  <div
  style="width: fit-content;"
    fxLayout="row"
    fxLayoutAlign="flex-start center"
    *heFeatureFlag="{ Name: 'create-project-btn', Page: 'common' }"
    
  >
    <button mat-icon-button (click)="toggleSideBar()">
      <mat-icon>chevron_right</mat-icon>
    </button>
  </div>
</mat-nav-list>
