import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { DynamicScoreInput } from '../../horizon-project/models/DynamicValueScore';
import { ValueForecastInput } from '../../horizon-project/models/ValueForecastInput';
import { ValueScoreInput } from '../../horizon-project/models/ValueScoreInput';
import { NetPricePermission, UserProjectPermission } from '../../horizon-project/models/UserProjectPermission';
import { BehaviorSubject } from 'rxjs';
import { AnalogueTableInterface } from '../../modules/project/models/criteria.interface';
export interface Dropdown {
  id: number;
  value: string;
  viewValue: string;
  visible: boolean;
  disabled: boolean;
}
@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  public scenarioList = new BehaviorSubject<any>([]);
  public castScenarioList = this.scenarioList.asObservable();
  public netPricePermission:NetPricePermission = {
    isNetPriceAvailable: false,
    isNetPriceVisible: false
  }
  onCountryChanged = new Subject<string>();
  private selectedCountry = '';

  onBrandChanged = new Subject<string[]>();
  private selectedBrands = [] as string[];

  private countries = [] as string[];

  onPriceTypeChanged = new Subject<string>();
  private selectedPriceType = '';

  public valueScoreChanged = new BehaviorSubject<boolean>(false);
  public castvalueScoreChanged = this.valueScoreChanged.asObservable();

  public enableLocalCurrency = new BehaviorSubject<any>(true);
  public castenableLocalCurrency = this.enableLocalCurrency.asObservable();
  public IsOncologyFrameworkSelected: boolean = false;
  public ComparatorOrAnalog = new BehaviorSubject<string>('');
  public AnalogueDataSource=new BehaviorSubject<AnalogueTableInterface[]>([]);
  public projectName = new BehaviorSubject<string>('');  
  public FrameworkID = new BehaviorSubject<string>('');
  public AssetName = new BehaviorSubject<string>('');
  public priceTypes: Dropdown[] = [
    { id: 1,value: 'launchPrice', viewValue: 'Launch Price',visible:true,disabled:false },
    { id: 2,value: 'reimbursedPrice', viewValue: 'Reimbursed Price',visible:true,disabled:false},
    {id: 3, value: 'currentPrice', viewValue: 'Current Price',visible:true,disabled:false },
    {id: 4, value: 'netPrice', viewValue: 'Net Price',visible:true,disabled:false },
  ];

  onValueScoreChanged = new Subject<DynamicScoreInput>();
  private valueScoreData: DynamicScoreInput = {
    UpdatedCountryScores: [],
    UpdatedValueScores: [],
  };

  onTabCliked = new Subject<string>();
  private activeTabName = '';

  public scenarioName = '';
  onScenarioChange = new Subject<string>();

  public analogAndComparator = new BehaviorSubject<any>([]);

  onUserProjectPermissionChanged = new Subject<UserProjectPermission[]>();
  private userProjectPermission: UserProjectPermission[] = [];
  public assetName = new BehaviorSubject<any>('');
  public castAssetName = this.assetName.asObservable();
  public analogueViewmode: string = 'grid';
  setSelectedCountry(country: string) {
    this.selectedCountry = country.replace('-', ' ');
    this.onCountryChanged.next(country.replace('-', ' '));

  }

  getSelectedCountry() {
    return this.selectedCountry.replace('-', ' ');
  }

  setSelectedBrands(brands: string[]) {
    this.selectedBrands = brands;
    this.onBrandChanged.next(brands);
  }

  getSelectedBrands() {
    return this.selectedBrands;
  }

  setCountries(countries: string[]) {
    this.countries = countries;
  }

  getCountries() {
    return this.countries;
  }

  setSelectedPriceType(priceType: string) {
    this.selectedPriceType = priceType;
    this.onPriceTypeChanged.next(priceType);
  }

  getSelectedPriceType() {
    return this.selectedPriceType;
  }

  setValueScoredata(data: DynamicScoreInput) {
    this.valueScoreData = data;
    this.onValueScoreChanged.next(data);
  }

  getUpdatedValueScoreData() {
    return this.valueScoreData;
  }

  setActiveTabName(tabName: string) {
    this.activeTabName = tabName;
    this.onTabCliked.next(tabName);
  }

  getActiveTabName() {
    return this.activeTabName;
  }

  setScenario(scenario: string) {
    this.scenarioName = scenario;
    this.onScenarioChange.next(scenario);
  }

  getScenario() {
    return this.scenarioName;
  }

  setUserProjectPermission(permissions: UserProjectPermission[]) {
    (this.userProjectPermission = permissions),
      this.onUserProjectPermissionChanged.next(permissions);
  }

  getUserProjectPermission() {
    return this.userProjectPermission;
  }

  public setEnableCurrenct(
    state: boolean,
    tabName: string,
    viewMode?: string
  ): void {
    if (viewMode) {
      this.analogueViewmode = viewMode;
    }
    if (tabName === 'Analogues' && this.analogueViewmode === 'chart') {
      this.enableLocalCurrency.next(false);
    } else {
      this.enableLocalCurrency.next(state);
    }
  }

  public setPriceTypes():void{

  }
}
