import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { AuthGuardService } from './auth/auth-guard.service';
import { MsadModule } from './msad';
import { RoutesEnum } from './common';
import { ExchangeRatesComponent } from './exchange-rates/exchange-rates.component';
import { PlatformGuardService } from './auth/platform-guard.service';
import { AccessDeniedComponent } from './access-denied/access-denied.component';
import { MemberAuthGuard } from './member-auth.guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [MsalGuard],
    children: [
      {
        redirectTo: `/${RoutesEnum.Home}`,
        path: '',
        pathMatch: 'full',
      },
      {
        path: RoutesEnum.MSAD,
        loadChildren: () => Promise.resolve(MsadModule),
      },
      {
        path: RoutesEnum.Home,
        data: {
          platforms: ['Pulse'],
        },
        canActivate: [PlatformGuardService,MemberAuthGuard],
        loadChildren: () =>
          import(/* webpackChunkName: 'home' */ './home/page.module').then(
            (Module) => Module.HomePageModule
          ),
      },
      {
        path: RoutesEnum.Horizon,
        data: {
          platforms: ['Horizon'],
        },
        canActivate: [PlatformGuardService,MemberAuthGuard],
        loadChildren: () =>
          import(
            /* webpackChunkName: 'horizon' */ './horizon/page.module'
          ).then((Module) => Module.HorizonPageModule),
      },
      {
        path: RoutesEnum.Insights,
        canActivate: [MemberAuthGuard],
        loadChildren: () =>
          import(
            /* webpackChunkName: 'datastore' */ './datastore/datastore.module'
          ).then((Module) => Module.DatastoreModule),
      },
      {
        path: RoutesEnum.ExchangeRates,
        canActivate: [MemberAuthGuard],
        loadChildren: () =>
          import(
            /* webpackChunkName: 'exchange-rates' */ './exchange-rates/exchange-rates.module'
          ).then((Module) => Module.ExchangeRateModule),
      },
      {
        path: RoutesEnum.Projects,
        canActivate: [AuthGuardService, MemberAuthGuard],
        loadChildren: () =>
          import(
            /* webpackChunkName: 'project-catalog' */ './project-catalog/page.module'
          ).then((Module) => Module.ProjectCatalogPageModule),
      },
      {
        path: RoutesEnum.Builder,
        canActivate: [MemberAuthGuard],
        loadChildren: () =>
          import(
            /* webpackChunkName: 'builder' */ './cms/cms-builder/cms-builder.module'
          ).then((Module) => Module.CmsBuilderModule),
      },
      {
        path: RoutesEnum.Project,
        canActivate: [AuthGuardService, MemberAuthGuard],
        children: [
          {
            redirectTo: `/${RoutesEnum.Projects}`,
            path: '',
            pathMatch: 'full',
          },
          {
            path: ':id',
            loadChildren: () =>
              import(
                /* webpackChunkName: 'project-details' */ './project-details/page.module'
              ).then((Module) => Module.ProjectDetailsPageModule),
          },
        ],
      },
      {
        path: RoutesEnum.AtlasPage,
        canActivate: [AuthGuardService, MemberAuthGuard],
        loadChildren: () =>
          import(
            /* webpackChunkName: 'pulse-atlas' */ './pulse-atlas/pulse-atlas.module'
          ).then((Module) => Module.PulseAtlasModule),
      },
      {
        path: RoutesEnum.Indications,
        canActivate: [MemberAuthGuard],
        loadChildren: () =>
          import(
            /* webpackChunkName: 'indications' */ './indications/indications.module'
          ).then((Module) => Module.IndicationsModule),
      },
      {
        path: RoutesEnum.FAQ,
        canActivate: [AuthGuardService, MemberAuthGuard],
        loadChildren: () =>
          import(/* webpackChunkName: 'faqs' */ './faqs/faqs.module').then(
            (Module) => Module.FaqsModule
          ),
      },
      {
        path: RoutesEnum.PlatformUserAuthorization,
        loadChildren: () =>
          import(
            /* webpackChunkName: 'platform-user-authorization' */ './platform-user-authorization/platform-user-authorization.module'
          ).then((Module) => Module.PlatformUserAuthorizationModule),
      },
      {
        path: RoutesEnum.InstantInsights,
        canActivate: [AuthGuardService, MemberAuthGuard],
        loadChildren: () =>
          import(
            /* webpackChunkName: 'instant-insights' */ './instant-insights/instant-insights.module'
          ).then((Module) => Module.InstantInsightsModule),
      },
      {
        path: RoutesEnum.UsaAdditionalPrices,
        canActivate: [AuthGuardService, MemberAuthGuard],
        loadChildren: () =>
          import(
            /* webpackChunkName: 'usa-additional-prices' */ './usa-additional-price/usa-additional-price.module'
          ).then((Module) => Module.UsaAdditionalPriceModule),
      },
      {
        path: RoutesEnum.NewChat,
        loadChildren: () =>
          import('./chat-gpi/chat/chat.module').then((m) => m.ChatModule),
      },
      {
        path: RoutesEnum.ChatGpi,
        loadComponent: () => import('./chat-gpi/chat-gpi.component').then(m => m.ChatGpiComponent)
      },
    ],
  },
  {
    path: '**',
    component: AccessDeniedComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
