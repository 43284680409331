import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FiltersValueService } from '../../filters-value.service';
import { FilterNameEnum, FiltersObject } from '../../interfaces';

@Component({
  selector: 'he-filter-compare-with',
  templateUrl: './filter-compare-with.component.html',
  styleUrls: ['./filter-compare-with.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterCompareWithComponent {
  @Input() filtersValue!: FiltersObject;
  @Output() filtersValueModified: EventEmitter<FiltersObject> = new EventEmitter();

  constructor(public filtersValueService: FiltersValueService) {
  }

  get isSelected(): boolean {
    return this.filtersValueService.findItemInFilters(this.filtersValue, FilterNameEnum.comparatorDrug).filterValue[0].name;
  }

  onChange(): void {
  //  this.filtersValueModified.emit(this.filtersValueService.updateItemInFilters(FilterNameEnum.comparatorDrug, [{ name: !this.isSelected }]));
  }
}
