<div class="forecast-summary-card" *ngIf="!loading">
  <!-- <div class="summary-analogues">
    <p>
      Forecast annual cost of treatment was generated using a value-price
      analysis considering the following analogues:
    </p>

    <div class="summary-analogue-title">
      <span class="summary-analogue-subtitle"
        >ANALOGUE
        <span class="reset-button">
          <mat-icon>restart_alt</mat-icon>
        </span>

      </span>
      <div class="analogue-summary-list" *ngFor="let analogue of analogues" (click)="removeAnalogues(analogue)">
        <div
          class="analogue-color-circle"
          [ngStyle]="{ background: analogue.color }"
        ></div>
        <p>{{ analogue.name }}</p>
      </div>
    </div>
  </div> -->
  <div class="summary-scenarios">
    <div style="width: fit-content;min-width: 100%;">
      <div style="display: flex;">
        <div style="width: 276px;">
        </div>
        <div class="summary-country-labels">
    
          <div class="summary-country-label" *ngFor="let country of countries">
            <img src="assets/flags/small/{{ country?.flagId }}.svg" height="17px" />
            <p>{{ country.name }}</p>
            <!-- <mat-icon
              #tooltip="matTooltip"
              matTooltip="{{ country.info }}"
              matTooltipClass="horizon-tooltip"
              >info</mat-icon
            > -->
          </div>
        </div>
      </div>
    </div>


    <div
      style="width: fit-content; min-width: 100%"
      *ngFor="let Scenario of scenarios"
    >
      <div class="summary-scenarios-labels">
        <p>{{ Scenario.scenarioIndexName }}{{ Scenario.scenario }}</p>
      </div>
      <div
        *ngFor="let priceType of Scenario.priceTypes"
        class="summary-scenario-background">
        <div class="summary-scenario-prices">
          <div class="summary-price-label">
            <p>{{ priceType.priceType }}</p>
          </div>
          <div class="summary-scenario-values">
            <div class="price" *ngFor="let country of priceType.country">
              <div *ngIf="this.headerService.ComparatorOrAnalog.value === 'Analogues'" class="price-container">
                <p class="analog" *ngIf="country.price === '******'">{{country.price}}</p>  

                  <p class="analog" *ngIf="country.price > 0">
                    {{
                      country.price | currency : country.currency : "symbol-narrow" : "1.0-2"
                    }}
                  </p>
                  <p class="analog" *ngIf="country.price <= 0">NA</p>  
              </div>
            
              <div *ngIf="this.headerService.ComparatorOrAnalog.value === 'Comparators' && country.minprice!= country.maxprice" class="price-container">
                 <p class="compara" >
                  {{
                    country.minprice | currency : country.currency : "symbol-narrow" : "1.0-2"
                  }}                 
                  <span>to</span>
                  {{
                    country.maxprice | currency : country.currency : "symbol-narrow" : "1.0-2"
                  }}
                 </p> 
                  </div>
             
               <div *ngIf="this.headerService.ComparatorOrAnalog.value === 'Comparators'" class="price-container">
                <p class="compara" *ngIf="country.minprice== country.maxprice && country.minprice > 0">
                 {{
                    country.minprice | currency : country.currency : "symbol-narrow" : "1.0-2"
                  }}    
                </p>
                <p class="compara" *ngIf="country.minprice === '******'">{{country.minprice}}</p>  

                </div>
                <div *ngIf="this.headerService.ComparatorOrAnalog.value === 'Comparators' && country.minprice== country.maxprice && country.minprice <= 0" class="price-container">
                 <p class="compara">NA</p>   
                  </div>
                  <div>
                    <mat-icon
                      #tooltip="matTooltip"
                      matTooltip="{{ country.info }}"
                      matTooltipClass="horizon-tooltip"
                      >info</mat-icon >
                  </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="loading">
  <he-loading-spinner [diameter]="70" [height]="'350px'"></he-loading-spinner>
</div>
