import {
  Component,
  OnInit,
  AfterViewInit,
  EventEmitter,
  Output,
  Input,
  ElementRef,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import * as echarts from 'echarts';
import * as data from './db.json';
import { ValueScoringService } from '../../../valuescoring/services/value-scoring.service';
import { ActivatedRoute } from '@angular/router';
import { HeaderService } from '../../../../shared/services/header.service';
import { ExportImageService } from '../../../../shared/services/export-image.service';
import { ECharts } from 'echarts';
import html2canvas from 'html2canvas';
import { Subject, takeUntil } from 'rxjs';
import { Capitalise } from '../../../../horizon-project/utility-functions/StringFormatter';
import { ToastNotificationService } from '../../../../shared/services/toast-notification.service';

@Component({
  selector: 'he-project-forecast-value-assessment',
  templateUrl: './project-forecast-value-assessment.component.html',
  styleUrls: ['./project-forecast-value-assessment.component.scss'],
})
export class ProjectForecastValueAssessmentComponent implements OnInit, OnDestroy {
  @ViewChild('dataToExport', { static: false }) public dataToExport:
    | ElementRef
    | any;
  @ViewChild('downloadLink') downloadLink: ElementRef | any;
  @ViewChild('canvas') canvas: ElementRef | any;
  @Input() chartNameInput: any = '';
  @Output() chartInstance = new EventEmitter<any>();
  public chartOption: echarts.EChartsOption | undefined | any;
  public showSection = false;
  valueScoringData: any = [];
  selectedCountry = 'Germany';
  brandName: any = [];
  domainScores: any[] = [];
  seriesData: any[] = [];
  domainNames = [
    'Burden',
    'Product characteristics',
    'Trial design',
    'Clinical benefit',
    'Other',
  ];
  scenario = '';

  private unsubscriber$ = new Subject<void>();

  constructor(
    private valueScoringService: ValueScoringService,
    private route: ActivatedRoute,
    private headerService: HeaderService,
    private exportImageService: ExportImageService,
    private toastNotificationService: ToastNotificationService
  ) {}
  chartData: any[] = (data as any).default;
  projectId = '';

  ngOnInit(): void {
    this.selectedCountry = this.headerService.getSelectedCountry();

    this.route.queryParams.pipe(takeUntil(this.unsubscriber$)).subscribe((f) => {
      this.projectId = f.project;
      if (this.projectId) {
        this.brandName = [];
        this.domainScores = [];
        this.seriesData = [];
        this.scenario="";
        this.initChartOptions();
      }
    });

    this.headerService.onScenarioChange.pipe(takeUntil(this.unsubscriber$)).subscribe((data) => {
      this.scenario = data;
      if (this.scenario && this.projectId) {
        this.brandName = [];
        this.domainScores = [];
        this.seriesData = [];
        this.fetchData(this.projectId, this.scenario, this.selectedCountry);
      }
    });

    this.headerService.onValueScoreChanged.pipe(takeUntil(this.unsubscriber$)).subscribe((data) => {
      if (data) {
        const country = this.selectedCountry;
        const countryAssetScore: any = data.UpdatedValueScores.filter(
          (f: any) => f.country === country
        )[0];

        if (this.valueScoringData) {
          const assetIndex = this.valueScoringData.assetTLIs.findIndex(
            (f: any) => f.id === countryAssetScore.assetTLIs[0].id
          );

          if (assetIndex > -1) {
            this.valueScoringData.assetTLIs[assetIndex] = JSON.parse(
              JSON.stringify(countryAssetScore.assetTLIs[0])
            );

            this.brandName = [];
            this.domainScores = [];
            this.seriesData = [];
            this.initChartOptions();
            this.prepareChartData();
          }
        }
      }
    });

    this.headerService.onCountryChanged.pipe(takeUntil(this.unsubscriber$)).subscribe((data) => {
      this.selectedCountry = data;
      this.brandName = [];
      this.domainScores = [];
      this.seriesData = [];
      this.fetchData(this.projectId, this.scenario, this.selectedCountry);
    });
  }

  private initChartOptions() {
    this.chartOption = {
      color: ['#565FA2', '#9189C4', '#035D86', '#2891A3', '#A9A9BE'],
      legend: {
        left: 80,
        bottom: 20,
        orient: 'horizontal',
        itemGap: 20,
        icon: 'circle',
        textStyle: {
          align: 'right',
          padding: [0, 15, 0, 0],
        },
        data: this.domainNames,
      },
      tooltip: {},
      grid: {
        left: '4%',
        right: '4%',
        bottom: '25%',
        top: '15%',
        containLabel: true,
      },
      yAxis: {
        type: 'value',
        name: 'TOTAL VALUE SCORE',
        nameTextStyle: {
          color: 'black',
          fontSize: 14,
          fontWeight: 500,
        },
        nameLocation: 'middle',
        nameGap: 30,
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },

        splitLine: {
          lineStyle: {
            color: 'rgba(0, 0, 0, 0.16)',
          },
        },
      },
      xAxis: {
        type: 'category',
        name: 'PRODUCTS',
        nameLocation: 'middle',
        nameGap: 80,
        nameTextStyle: { fontWeight: 500, fontSize: 14, color: 'black' },
        data: this.brandName,
        axisTick: {
          show: false,
        },
        axisLabel: {
          color: 'black',
          fontSize: 11,
          fontWeight: 400,
          // interval: 0
          rotate:45
        },
      },
      series: [],
      media: [
        {
          option: {
            xAxis: {
              axisLabel: {
                fontSize: 11,
              },
            },
          },
        },
        {
          query: { maxWidth: 1665, minWidth: 1000 },
          option: {
            xAxis: {
              axisLabel: {
                fontSize: 10,
              },
            },
          },
        },
        {
          query: {
            maxWidth: 1000, // when container width is smaller than 500
          },
          option: {
            xAxis: {
              axisLabel: {
                fontSize: 8,
              },
            },
          },
        },
      ],
    };
  }

  async fetchData(projectId: string, scenario: string, country: string) {
    if (projectId && country && scenario) {
      await this.valueScoringService
        .getValueScoringData(projectId, country, scenario)
        .then(
          (dbData) => {
            this.valueScoringData = dbData;
            this.brandName = [];
            this.domainScores = [];
            this.seriesData = [];
            this.initChartOptions();
            this.prepareChartData();
          },
          (error) => {
            console.log(error);
            this.toastNotificationService.errorMessage(
              'Unable to fetch value scoring data',
              'info',
              10000
            );
          }
        );
    }
  }

  private prepareChartData() {
    this.valueScoringData.assetTLIs.sort((a:any, b:any) => {
      return a.sequenceNo - b.sequenceNo;
    })
    for (let i = 0; i < this.valueScoringData.assetTLIs.length; i++) {
      this.brandName.push(Capitalise(this.valueScoringData.assetTLIs[i].brandName));
      let burdonDomainValueScore = 0;
      let productDomainValueScore = 0;
      let trialDomainValueScore = 0;
      let clinicalDomainValueScore = 0;
      let otherDomainValueScore = 0;
      for (
        let j = 0;
        j <= this.valueScoringData.assetTLIs[i].valueDomains.length;
        j++
      ) {
        const valueDomain = this.valueScoringData.assetTLIs[i]?.valueDomains[j];
        if (valueDomain?.domainName === 'Burden') {
          burdonDomainValueScore = Math.round(parseFloat(
            valueDomain.domainValueScore.toFixed(2)
          ));
        }
        if (valueDomain?.domainName === 'Product characteristics') {
          productDomainValueScore = Math.round(parseFloat(
            valueDomain.domainValueScore.toFixed(2)
          ));
        }
        if (valueDomain?.domainName === 'Trial design') {
          trialDomainValueScore = Math.round(parseFloat(
            valueDomain.domainValueScore.toFixed(2)
          ));
        }
        if (valueDomain?.domainName === 'Clinical benefit') {
          clinicalDomainValueScore = Math.round(parseFloat(
            valueDomain.domainValueScore.toFixed(2)
          ));
        }
        if (valueDomain?.domainName === 'Other') {
          otherDomainValueScore = Math.round(parseFloat(valueDomain.domainValueScore));
        }
      }
      this.domainScores.push([
        burdonDomainValueScore,
        productDomainValueScore,
        trialDomainValueScore,
        clinicalDomainValueScore,
        otherDomainValueScore,
      ]);
    }
    this.domainScores = this.transpose(this.domainScores);

    for (let i = 0; i < this.domainNames.length; i++) {
      if (this.chartOption?.series) {
        this.chartOption.series.push({
          name: this.domainNames[i],
          type: 'bar',
          stack: 'x',
          barWidth: '60%',
          barCategoryGap: '10%',
          data: this.domainScores[i],
        });
      }
    }
    this.showSection = true;
  }

  transpose(arr: any[]) {
    return Object.keys(arr[0]).map(function (c) {
      return arr.map(function (r) {
        return r[c];
      });
    });
  }

  saveChartApi(ev: ECharts): void {
    this.chartInstance.emit(ev);
  }
  downlodFile() {
    this.exportImageService.saveImage(this.chartNameInput, this.chartNameInput);
  }
  public downloadAsPdf(): void {
    html2canvas(this.dataToExport.nativeElement).then(canvas => {
      this.canvas.nativeElement.src = canvas.toDataURL();
      this.downloadLink.nativeElement.href = canvas.toDataURL('image/png');
      this.downloadLink.nativeElement.download = this.chartNameInput + '.png';
      this.downloadLink.nativeElement.click();
    });
  }

  
  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }

}
