import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subject, take, takeUntil } from 'rxjs';
import { HorizonProjectService } from '../../../horizon-project/horizon-project.service';
import { UserProjectPermission } from '../../../horizon-project/models/UserProjectPermission';
import { HeaderService } from '../../services/header.service';
import { ProjectLoadService } from '../../../horizon-project/services/project-load.service';
import { ToastNotificationService } from '../../services/toast-notification.service';
import { UserProfile } from '../../../../profile/menu/user.profile';
import { GlobalSpinnerService } from 'projects/helios-gui/src/uikit/loading-spinner/global-spinner.service';
import { Store } from '@ngrx/store';
import { HorizonState } from '../../store/models/state.model';
import { LoadProjects, ResetProjects } from '../../store/actions/horizon.action';
import { selectGetProjectList } from '../../store/reducer/horizon.reducer';
import { ProjectDraftService } from '../../../modules/project/services/project-draft.service';
import { ProjectService } from '../../../modules/project/services/project.service';
import { AssetDynamicDataService } from '../../../horizon-project/services/asset-dynamic-data.service';
import { FeatureFlagService } from '../../../horizon-project/services/feature-flag.service';
import { MatDialog } from '@angular/material/dialog';
import { UpdateFrameworkComponent } from '../update-framework/update-framework.component';
import { formatDate } from '@angular/common';
import { UpdateFrameworkService } from '../../services/update-framework.service';
import { SharedService } from 'projects/helios-gui/src/uikit/service/shared.service';
export interface ProjectDetail {
  id: string; // unique project id
  name: string; // asset name
  toplevelIndication: string; // top level indication
  modifiedOn: string; // created date in string format
}

@Component({
  selector: 'he-app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, OnDestroy {
  @Output() toggleSideBarForMe: EventEmitter<any> = new EventEmitter();
  @Input() public sideBarOpen = true;
  listProjects!: any;
  displayedColumns: string[] = ['name', 'createdOn'];
  dataSource: any[] = [];
  isCreateProject: boolean = true;
  isLoading: boolean = false;
  isDraftProject: boolean = false;
  saveDraftFeature: boolean = false;
  netPriceFeature: boolean = false;
  updateFramework: boolean = false;
  currentProjectId: any = '';
  public userEmailId: string = '';
  public userProjectPermissions: UserProjectPermission[] = [];

  private unsubscriber$ = new Subject<void>();

  constructor(
    private router: Router,
    private projectService: HorizonProjectService,
    private createprojectService: ProjectService,
    private assetDynamicDataService: AssetDynamicDataService,
    private headerService: HeaderService,
    public activatedRouter: ActivatedRoute,
    private projectLoadService: ProjectLoadService,
    private toastService: ToastNotificationService,
    private currentProfile: UserProfile,
    private globalSpinnerService: GlobalSpinnerService,
    private store: Store<HorizonState>,
    private projectDraftService: ProjectDraftService,
    private featureFlagService: FeatureFlagService,
    public dialog: MatDialog,
    public updateFrameworkService: UpdateFrameworkService,
    private sharedService:SharedService
  ) {}

  ngOnInit(): void {
    this.sharedService.isTotalValuescoreByCountriesCall$.next(false);
    this.sharedService.isBrandCotDataCall$.next(false);    
    this.globalSpinnerService.loadingSpinner(true);
    this.featureFlagService.getFeatureFlagData().then((data) => {
       this.saveDraftFeature = data.find(
        (x) => x.name === 'project-save-as-draft' && x.page === 'common'
      )?.isActive as boolean;
      this.netPriceFeature = data.find(
        (x) => x.name === 'net-price-assumptions' && x.page === 'project-sidebar'
      )?.isActive as boolean;
      this.updateFramework = data.find(
        (x) => x.name === 'update-framework' && x.page === 'dashboard'
      )?.isActive as boolean;
    });
    this.store
      .select(selectGetProjectList)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((res) => {
        if (res.length != 0) {
          this.listProjects = res;
          this.dataSource = res;

          if (Array.isArray(res)) {
             this.userProjectPermissions = res.map(
              (f) =>
                <UserProjectPermission>{
                  projectId: f.id,
                  permissions: f.permissions,
                }
            );
             this.currentProjectId = res[0].id;

            if (this.currentProjectId) {
              const currentProjectPermission = this.userProjectPermissions.find(
                (f) => f.projectId === this.currentProjectId
              );
              if (currentProjectPermission) {
                this.isCreateProject =
                  currentProjectPermission.permissions.project.edit;
              }

              this.headerService.setUserProjectPermission(
                this.userProjectPermissions
              );

              const queryParams: Params = { project: this.currentProjectId };
              this.activatedRouter.queryParams.pipe(take(1)).subscribe((p) => {
                if (p.project == undefined || p.project == '') {
                  this.router.navigate([], {
                    queryParams,
                    queryParamsHandling: 'merge',
                  });
                }else{
                  this.currentProjectId =  p.project
                }
                if(this.netPriceFeature){
                  const netProjectPermission = this.userProjectPermissions.find(
                    (f) => f.projectId === this.currentProjectId
                  )
                  if (netProjectPermission) {
                    this.headerService.netPricePermission = netProjectPermission.permissions.netPrice;
                  }
                }
              });
            }
          }
        }
      });
    //testing ngrx
    // this.projectService.getProjectList().then((data) => {
    //   this.listProjects = data;
    //   this.dataSource = data;
    //   if (Array.isArray(data)) {
    //     const userProjectPermissions = data.map(
    //       (f) =>
    //         <UserProjectPermission>{
    //           projectId: f.id,
    //           permissions: f.permissions,
    //         }
    //     );

    //     const currentProjectId = data[0].id;

    //     if (currentProjectId) {
    //       const currentProjectPermission = userProjectPermissions.find(
    //         (f) => f.projectId === currentProjectId
    //       );

    //       if (currentProjectPermission) {
    //         this.isCreateProject =
    //           currentProjectPermission.permissions.project.edit;
    //       }

    //       this.headerService.setUserProjectPermission(userProjectPermissions);

    //       const queryParams: Params = { project: currentProjectId };
    //       this.activatedRouter.queryParams.pipe(take(1)).subscribe((p) => {
    //         if (p.project == undefined || p.project == '') {
    //           this.router.navigate([], {
    //             queryParams,
    //             queryParamsHandling: 'merge',
    //           });
    //         }
    //       });

    //     }
    //   }
    // });
    this.projectLoadService.getLoading().subscribe((data) => {
      this.isLoading = data;
    });

    this.projectDraftService.checkDraftProjectExist().then((res: any) => {
      if (res.data) {
        this.isDraftProject = res.data;
          if (!this.saveDraftFeature) {
            this.isDraftProject = false;
          }
      }
    });

    this.userEmailId = this.currentProfile.getEmail();


    this.activatedRouter.queryParams
    .pipe(takeUntil(this.unsubscriber$))
    .subscribe( (f) => {
      this.currentProjectId = f.project;
      if(this.netPriceFeature){
        const currentProjectPermission = this.userProjectPermissions.find(
          (f) => f.projectId === this.currentProjectId
        );
        if (currentProjectPermission) {
          this.headerService.netPricePermission = currentProjectPermission.permissions.netPrice;
        }
      }

    })
  }

  toggleSideBar(): void {
    this.toggleSideBarForMe.emit();
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 300);
  }

  showNotification(project:any,index:number) {
    // if(this.updateFramework){
    //   if(project.projectFrameworkUpgradeLogsDTO.length === 0){
    //     if(project.updatedFrameworkDTO != null){
    //       if(formatDate(project.modifiedOn,'yyyy-MM-dd','en_US') < formatDate(project.updatedFrameworkDTO.framework_Updateddate,'yyyy-MM-dd','en_US')){
    //         console.log('yes')
    //             this.dialog.open(UpdateFrameworkComponent, {
    //         width: '600px',
    //         panelClass: 'framework-dialog',
    //         backdropClass: 'framework-dialog-backdrop',
    //       }).afterClosed().subscribe(res=>{
    //         if(res === true){
              
    //           this.updateFrameworkService.isUpdateFrameWork.next(true);
    //           this.updateFrameworkService.updateFrameWorkId.next(project.updatedFrameworkDTO.updated_ValueFrameworkId);
    //           this.createprojectService.editProjectId.next(project.id);
    //           let link = "/horizon/edit-project/" + project.id
    //           this.router.navigate([link]);
    //         }else if(res === false){
    //           this.updateFrameworkService.updateFrameWorkLog(project.id,false)
    //           console.log(this.dataSource[index].projectFrameworkUpgradeLogsDTO)
    //           // let projectFrameworkUpgradeLogsDTO = {
    //           //   createdBy:  null,
    //           //   createdOn:  null,
    //           //   decision : false,
    //           //   projectId : null,
    //           //   updated_ValueProjectId :  null,
    //           // }
    //           // this.dataSource[index].projectFrameworkUpgradeLogsDTO.push(projectFrameworkUpgradeLogsDTO)
    //         }
    //       });
    //       }
    //     }
    //   }
  
    // }


    //for new updated framework
    // this.sharedService.selectedTabIndex$.next(0)
    this.sharedService.isTotalValuescoreByCountriesCall$.next(false);
    this.sharedService.isBrandCotDataCall$.next(false)
    this.assetDynamicDataService.emptyData();
    if (this.isLoading) {
      this.toastService.successMessage(
        'Project data is loading, please wait!',
        'info'
      );
    }
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
